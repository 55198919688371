import { PrivateApiCall } from "../config/interceptors";
import { errorCatch } from "../helpers/error";

async function GetLeads() {
    const apiCall = await PrivateApiCall.get("/admin/leads")
      .then((response) => response)
      .catch((error) => errorCatch(error, "Error getting leads"));
    return apiCall;
  }

  export {
    GetLeads
  }