import { PrivateApiCall } from "../config/interceptors";
import { errorCatch } from "../helpers/error";

async function GetActivities() {
  const apiCall = await PrivateApiCall.get("/admin/activity")
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error getting Activities"));
  return apiCall;
}

async function CreateActivity(data) {
  const apiCall = await PrivateApiCall.post("/admin/activity", data)
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error creating Activity"));
  return apiCall;
}

async function UpdateActivity(data, id) {
  const apiCall = await PrivateApiCall.put(`/admin/activity/${id}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error updating Activity"));                            
  return apiCall;
}

async function DeleteActivity(id) {
  const apiCall = await PrivateApiCall.delete(`/admin/activity/${id}`)
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error deleting Activity"));
  return apiCall;
}

async function ActivateActivity(id, data) {
  const apiCall = await PrivateApiCall.put(
    `/admin/activity/activate/${id}`,
    data
  )
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error updating Activity"));
  return apiCall;
}

async function PublishActivity(id, data) {
  const apiCall = await PrivateApiCall.put(
    `/admin/activity/publish/${id}`,
    data
  )
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error updating Activity"));
  return apiCall;
}

export {
  GetActivities,
  CreateActivity,
  DeleteActivity,
  UpdateActivity,
  ActivateActivity,
  PublishActivity,
};
