import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import React, { useEffect, useRef, useState } from 'react'
import { showToast } from '../../App';
import { DeleteImage, UploadImages } from '../../network/Upload.network';

const ActivityImages = (props) => {
    const dt = useRef(null);
    const [images, setImages] = useState(props.Activity?.images)
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(props.Activity?.images?.length || 0);
    const [addModalVisible, setAddModalVisible] = useState(false)
    const [selectedImage, setSelectedImage] = useState()
    const [image, setImage] = useState(null)
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
        filters: {
            title: { value: "", matchMode: "contains" },
            description: { value: "", matchMode: "contains" },
            startDate: { value: "", matchMode: "contains" },
            endDate: { value: "", matchMode: "contains" },
            keyPoints: { value: "", matchMode: "contains" },
            createdAt: { value: "", matchMode: "contains" },
        },
    });

    useEffect(() => {
        setImage(null)
        setSelectedImage(null)
    },[addModalVisible])

    const applyPaginationAndFilters = () => {
        let filteredData = images;

        if (!images) {
            return []; // Return an empty array if tableRecords data is null
        }
        // Apply filters
        Object.keys(lazyState.filters).forEach((key) => {
            const filterValue = lazyState.filters[key].value;
            if (filterValue) {
                filteredData = filteredData.filter((item) =>
                    String(item[key]).toLowerCase().includes(filterValue.toLowerCase())
                );
            }
        });

        // Apply sorting
        if (lazyState.sortField && lazyState.sortOrder) {
            const sortMultiplier = lazyState.sortOrder === 1 ? 1 : -1;
            filteredData.sort((a, b) => {
                const valueA = a[lazyState.sortField];
                const valueB = b[lazyState.sortField];
                if (valueA < valueB) {
                    return -1 * sortMultiplier;
                } else if (valueA > valueB) {
                    return 1 * sortMultiplier;
                }
                return 0;
            });
        }

        // Apply pagination
        const { first, rows } = lazyState;
        const paginatedData = filteredData.slice(first, first + rows);

        return paginatedData;
    };

    const onPage = (event) => {
        setlazyState(event);
    };
    const onSort = (event) => {
        setlazyState(event);
    };

    const onFilter = (event) => {
        event["first"] = 0;
        setlazyState(event);
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedImage(file)
            setImage(URL.createObjectURL(file));
        }
    };

    const handleDocument = async () => {
        if (!selectedImage) {
            return showToast('warn', 'No image added')
        }
        setLoading(true)
        const formData = new FormData()
        formData.append('image', selectedImage)
        formData.append('type', 'activity')
        await UploadImages(props.Activity?.id, formData).then(response => {
            if (response?.status === 200) {
                setImages(prev => [...prev, response?.data?.data])
                props.setActivities(prevActivities => 
                    prevActivities.map(activity =>
                      activity.id === props.Activity?.id
                        ? { ...activity, images: [...activity?.images, response?.data?.data] }
                        : activity
                    )
                  );
                setTotalRecords(prev => prev + 1)
                showToast('success', 'Image added successfully')
                setAddModalVisible(false)
                setImage(null)
            }
        }).catch(err => {
            console.error('Error uploading image', err);
            showToast('error', 'Something went wrong. Please try again!');
        })
            .finally(() => {
                setLoading(false)
            });
    }

    const header = (
        <div className="flex items-center justify-end">
            <Button
                label='Add'
                type="button"
                icon="pi pi-plus"
                onClick={() => setAddModalVisible(true)}
                tooltip="Add New Image"
                className="bg-custom-blue text-white hover:bg-white hover:text-custom-blue border-custom-blue"
                tooltipOptions={{ position: 'top' }}
            />
        </div>
    );

    const header1 = (
        <h1 className='text-center text-2xl text-custom-blue'>Activity Images</h1>
    )

    const header2 = (
        <h1 className='text-center text-2xl text-custom-blue'>Add Image</h1>
    )

    const accept = async (data) => {
        let obj = {
            type: 'activity',
            imageUrl: data
        }
        await DeleteImage(props.Activity?.id, obj).then(response => {
            if (response?.status === 200) {
                setImages(prevImages => prevImages.filter(image => image !== obj?.imageUrl));
                props.setActivities(prevActivities => 
                    prevActivities.map(activity =>
                      activity.id === props.Activity?.id
                        ? { ...activity, images: activity?.images?.filter(image => image !== data) }
                        : activity
                    )
                  );
                  setTotalRecords(prev => prev -1)
                return showToast('success', 'Image deleted successfully')
            } else {
                return showToast('error', 'Error deleting Image. Please try again!')
            }
        }).catch((error) => {
            console.error('Error deleting Image:', error);
            showToast('error', 'An error occurred while deleting Image.');
        })
    }

    const confirm2 = (data) => {
        const acceptWithUserData = () => {
            // Pass the data to the accept function
            accept(data);
        };
        confirmDialog({
            message: 'Do you want to delete this document?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-danger',
            accept: acceptWithUserData
        });
    };

    const actionTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className='flex flex-row items-center gap-2'>
                    {/* <Button icon="pi pi-file-export" tooltip="Download Document" tooltipOptions={{ position: 'top' }} onClick={() => confirm3(rowData)} style={{ height: '30px', width: '35px' }} size="small" className="bg-red-700 hover:bg-white hover:text-red-700 border-red-700 hover:border-red-700" /> */}
                    <Button icon="pi pi-delete-left" tooltip="Delete Document" tooltipOptions={{ position: 'top' }} onClick={() => confirm2(rowData)} style={{ height: '30px', width: '35px' }} size="small" className="bg-red-700 hover:bg-white hover:text-red-700 border-red-700 hover:border-red-700" />
                </div>
            </React.Fragment>
        );
    };

    return (
        <Dialog
            header={header1}
            visible={props.visible}
            onHide={() => props.setVisible(false)}
            className='w-auto'
        >
            <DataTable
                value={applyPaginationAndFilters()}
                header={header}
                lazy
                dataKey="id"
                paginator
                first={lazyState.first}
                totalRecords={totalRecords}
                rows={lazyState.rows}
                onPage={onPage}
                onSort={onSort}
                sortField={lazyState.sortField}
                sortOrder={lazyState.sortOrder}
                onFilter={onFilter}
                filters={lazyState.filters}
                filterDisplay="row"
                loading={loading}
                ref={dt}
                scrollable
                scrollHeight="60vh"
                selectionMode="single"
                rowsPerPageOptions={[5, 10, 25]}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} images"
            >
                <Column field="image" body={rowData => rowData} header="Image" />
                <Column frozen className="actionwidth" body={actionTemplate} />
            </DataTable>
            {addModalVisible &&
                <Dialog
                    header={header2}
                    visible={addModalVisible}
                    onHide={() => setAddModalVisible(false)}
                    className='w-auto '
                >
                    <div className="flex flex-col gap-2 w-full">
                        <label htmlFor="image">Image</label>
                        <span>
                            <input type="file" onChange={handleImageChange} />
                        </span>
                        {image && (
                                <img src={image} alt="Selected" className="mt-2" style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
                            )}
                        <div className='w-full'>
                            <Button
                                type="button"
                                label={loading ? 'Adding...' : 'Add Image'}
                                className="bg-custom-blue text-white hover:bg-light-blue-2 hover:text-custom-blue border-custom-blue w-full"
                                disabled={loading}
                                onClick={handleDocument}
                            />
                        </div>
                    </div>
                </Dialog>
            }
        </Dialog>
    )
}

export default ActivityImages