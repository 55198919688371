import { ErrorMessage, Field, Formik } from 'formik';
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react'
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { showToast } from '../../App';
import { GetRoles } from '../../network/Roles.network';
import { CreateAdmin } from '../../network/Admins.network';
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

const AddAdmin = (props) => {
    const [roles, setRoles] = useState([])

    const header = (
        <h1 className='text-center text-2xl text-custom-blue'>Add Admin</h1>
    )

    useEffect(() => {
        const fetchRoles = async () => {
            await GetRoles().then(response => {
                if (response?.status === 200) {
                    setRoles(response.data.data)
                }
            })
        }
        fetchRoles()
    }, [])

    return (
        <Dialog
            header={header}
            visible={props.visible}
            onHide={() => props.setVisible(false)}
            className='w-auto'
        >
            <Formik
                initialValues={{ firstname: '', lastname: '', email: '', phone: '', password: '', roleid: '' }}
                validate={values => {
                    const errors = {};
                    if (!values.firstname) {
                        errors.firstname = 'First name is required.';
                    }
                    if (!values.lastname) {
                        errors.lastname = 'Last name is required.';
                    }

                    if (!values.password) {
                        errors.password = 'Password is required.';
                    } else if (
                        !/^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(values.password)
                    ) {
                        errors.password = 'Password must be strong.';
                    }

                    if (!values.phone) {
                        errors.phone = 'Phone is required.';
                    }
                    if (!values.roleid) {
                        errors.roleid = 'Role is required.';
                    }
                    if (!values.email) {
                        errors.email = 'Email is required';
                    } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                    ) {
                        errors.email = 'Invalid email address';
                    }
                    return errors;
                }}
                onSubmit={async (values, { setSubmitting }) => {
                    if (!isValidPhoneNumber(values.phone)) {
                        return showToast('error', 'Not valid phone number!')
                    }
                    await CreateAdmin(values).then(response => {
                        if (response?.status === 200) {
                            props.setAdmins(prevUsers => [...prevUsers, response?.data])
                            props.setTotalRecords(prev => prev + 1)
                            showToast('success', 'User created successfully!')
                            props.setVisible(false)
                        } else {
                            return showToast('error', response?.data?.error ? response?.data?.error : 'Error creating user.')
                        }

                    }).catch(error => {
                        console.error('Error submitting form:', error);
                        showToast('error', 'An error occurred while submitting the form.');
                    })
                        .finally(() => {
                            setSubmitting(false);
                        });
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                }) => (
                    <form onSubmit={handleSubmit} className='text-custom-blue flex flex-col justify-center items-center gap-8 mt-6 w-full'>
                        <div className='flex lg:flex-row flex-col gap-5'>
                            <div className="flex flex-col gap-2">
                                <span className="p-float-label">
                                    <Field as={InputText} id="firstname" name="firstname" />
                                    <label htmlFor="firstname">First name</label>
                                </span>
                                <ErrorMessage name="firstname" component="div" className="text-red-500" />
                            </div>
                            <div className="flex flex-col gap-2">
                                <span className="p-float-label">
                                    <Field as={InputText} id="lastname" name="lastname" />
                                    <label htmlFor="lastname">Last name</label>
                                </span>
                                <ErrorMessage name="lastname" component="div" className="text-red-500" />
                            </div>
                            <div className="flex flex-col gap-2">
                                <span className="p-float-label">
                                    <Field as={InputText} id="email" name="email" />
                                    <label htmlFor="email">Email</label>
                                </span>
                                <ErrorMessage name="email" component="div" className="text-red-500" />
                            </div>
                        </div>
                        <div className='flex lg:flex-row flex-col gap-5 items-center'>
                            <div className="flex flex-col gap-2">
                                <span className="p-float-label">
                                    <Field as={InputText} id="password" label={"Password"} name="password" type={"password"} />
                                    <label htmlFor="password">Password</label>
                                </span>
                                <ErrorMessage name="password" component="div" className="text-red-500" />
                            </div>
                            <div className="flex flex-col gap-2">
                                <PhoneInput
                                    id="phone"
                                    defaultCountry="CH"
                                    value={values.phone}
                                    onChange={(e) => setFieldValue('phone', e)}// Set Formik value
                                    className="w-full p-3 border rounded-lg text-lg"
                                />
                                <ErrorMessage name="phone" component="div" className="text-red-500" />
                            </div>
                            <div className="flex flex-col gap-2">
                                <Field
                                    as={Dropdown}
                                    id="roleid"
                                    name="roleid"
                                    value={values.roleid}  // Ensure this is correctly bound
                                    options={roles || null}
                                    optionLabel="name"
                                    optionValue="id"
                                    onChange={handleChange}
                                    placeholder="Select a Role"
                                />
                                <ErrorMessage name="roleid" component="div" className="text-red-500" />
                            </div>
                        </div>
                        <Button
                            type="submit"
                            label={isSubmitting ? 'Submitting...' : 'Submit'}
                            // icon="pi pi-user-plus"
                            className="bg-custom-blue text-white hover:bg-light-blue-2 hover:text-custom-blue border-custom-blue w-full"
                            disabled={isSubmitting} />
                    </form>
                )}
            </Formik>
        </Dialog>
    )
}

export default AddAdmin