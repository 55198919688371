import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import PagesLayout from "../../layout/pagesLayout";
import { showToast } from "../../App";
import { Tag } from "primereact/tag";
import * as XLSX from 'xlsx';
import { ActivateActivity, DeleteActivity, GetActivities, PublishActivity } from "../../network/Activity.network";
import AddActivity from "./AddActivity";
import EditActivity from "./EditActivity";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { ConvertDateTime } from "../../helpers/convertDateTime";
import ActivityImages from "./ActivityImages";
import ActivityDocuments from "./ActivityDocuments";

const items = [{ label: "Activities" }];

function Activities(props) {
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [Activities, setActivities] = useState([]);
    const [addModalVisible, setAddModalVisible] = useState(false)
    const [selectedActivity, setSelectedActivity] = useState({})
    const [editModalVisible, setEditModalVisible] = useState(false)
    const [imageVisible, setImageVisible] = useState(false)
    const [documentVisible, setDocumentVisible] = useState(false)

    const dt = useRef(null);

    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
        filters: {
            title: { value: "", matchMode: "contains" },
            description: { value: "", matchMode: "contains" },
            content: { value: "", matchMode: "contains" },
            startDate: { value: "", matchMode: "contains" },
            endDate: { value: "", matchMode: "contains" },
            keyPoints: { value: "", matchMode: "contains" },
            createdAt: { value: "", matchMode: "contains" },
        },
    });

    const fetchActivities = async () => {
        try {
            setLoading(true)
            await GetActivities().then(response => {
                if (response?.status === 200) {
                    setActivities(response?.data?.data)
                    setTotalRecords(response?.data?.data?.length)
                } else {
                    showToast('error', 'Error Getting Activities')
                }
            })
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchActivities()
    }, []);

    const exportXLSX = () => {
        const exportData = Activities;
        if (exportData?.length > 0) {
            const exportedColumns = exportColumns.map((col) => col.dataKey);
            const exportedRows = exportData.map((Activity) =>
                exportedColumns.map((col) => {
                    const value = Activity[col];
                    return value;
                })
            );
            const worksheetData = [exportedColumns, ...exportedRows];

            const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Activities');

            // Generate a unique file name based on the current timestamp
            const fileName = 'Activity.xlsx';

            XLSX.writeFile(workbook, fileName);
        } else {
            showToast('error', 'No data found')
        }
    };

    //Columns and data to be displayed in exported PDF and Excel files
    const cols = [
        { field: "firstName", header: "First Name" },
        { field: "lastName", header: "Last Name" },
        { field: "title", header: "Title" },
        { field: "bio", header: "Bio" },
        { field: "email", header: "Email" },
        { field: "phone", header: "Phone" }
    ];

    const exportColumns = cols.map((col) => ({
        title: col.header,
        dataKey: col.field,
    }));


    const applyPaginationAndFilters = () => {
        let filteredData = Activities;

        if (!Activities) {
            return []; // Return an empty array if tableRecords data is null
        }
        // Apply filters
        Object.keys(lazyState.filters).forEach((key) => {
            const filterValue = lazyState.filters[key].value;
            if (filterValue) {
                filteredData = filteredData.filter((item) =>
                    String(item[key]).toLowerCase().includes(filterValue.toLowerCase())
                );
            }
        });

        // Apply sorting
        if (lazyState.sortField && lazyState.sortOrder) {
            const sortMultiplier = lazyState.sortOrder === 1 ? 1 : -1;
            filteredData.sort((a, b) => {
                const valueA = a[lazyState.sortField];
                const valueB = b[lazyState.sortField];
                if (valueA < valueB) {
                    return -1 * sortMultiplier;
                } else if (valueA > valueB) {
                    return 1 * sortMultiplier;
                }
                return 0;
            });
        }

        // Apply pagination
        const { first, rows } = lazyState;
        const paginatedData = filteredData.slice(first, first + rows);

        return paginatedData;
    };

    const onPage = (event) => {
        setlazyState(event);
    };
    const onSort = (event) => {
        setlazyState(event);
    };


    const header = (
        <div className="flex items-center justify-between gap-2">
            <Button
                type="button"
                icon="pi pi-file"
                severity="primary"
                onClick={() => exportXLSX()}
                className='bg-green-800 text-white hover:bg-white hover:text-green-800 border border-green-800'
                tooltip="Export to Excel"
            />
            <Button
                label='Create'
                type="button"
                icon="pi pi-user-plus"
                onClick={() => setAddModalVisible(true)}
                tooltip="Add New Activity"
                className="bg-custom-blue text-white hover:bg-white hover:text-custom-blue border-custom-blue"
                tooltipOptions={{ position: 'top' }}
            />
        </div>
    );

    const handleActive = (rowData) => {
        if (rowData?.isActive) {
            return <Tag severity="success" value="Active"></Tag>
        } else {
            return <Tag severity="danger" value="Inactive"></Tag>
        }
    }

    const onFilter = (event) => {
        event["first"] = 0;
        setlazyState(event);
    };

    const confirm2 = (data) => {
        confirmDialog({
            message: 'Do you want to delete this Activity?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-danger',
            accept:  async () => {
                await DeleteActivity(data?.id).then(response => {
                    if (response?.status === 200) {
                        const newAdmins = Activities.filter(admin => admin?.id !== data?.id)
                        setActivities(newAdmins)
                        setTotalRecords(prev => prev - 1)
                        return showToast('success', 'Activity deleted successfully')
                    } else {
                        return showToast('error', 'Error deleting Activity. Please try again!')
                    }
                }).catch((error) => {
                    console.error('Error deleting Activity:', error);
                    showToast('error', 'An error occurred while deleting Activity.');
                })
            }
        });
    };

    const handleActivate = async (rowData) => {
        try {
            setLoading(true);
            let data = { isActive: rowData?.isActive ? false : true }
            const response = await ActivateActivity(rowData?.id, data);
            if (response?.status === 200) {
                setActivities(prev => {
                    return prev.map(Activity => {
                        if (Activity.id === rowData?.id) {
                            return {
                                ...Activity, // Spread the existing admin data
                                isActive: data?.isActive
                            };
                        } else {
                            return Activity;
                        }
                    });
                });
                showToast('success', `${rowData?.isActive ? 'Activity Inactivated successfully!' : 'Activity activated sucessfully!'}`)
            } else {
                showToast('error', 'Error activating Activity');
            }
        } catch (error) {
            console.error('Something went wrong: ', error);
            showToast('error', 'Something went wrong. Please try again');
        } finally {
            setLoading(false);
        }
    }

    const handlePublish = async (rowData) => {
        try {
            setLoading(true);
            let data = { isPublished: rowData?.isPublished ? false : true }
            const response = await PublishActivity(rowData?.id, data);
            if (response?.status === 200) {
                setActivities(prev => {
                    return prev.map(Activity => {
                        if (Activity.id === rowData?.id) {
                            return {
                                ...Activity, // Spread the existing admin data
                                isPublished: data?.isPublished
                            };
                        } else {
                            return Activity;
                        }
                    });
                });
                showToast('success', `${rowData?.isVerified ? 'Activity Unpublished successfully!' : 'Activity published sucessfully!'}`)
            } else {
                showToast('error', 'Error publishing Activity');
            }
        } catch (error) {
            console.error('Something went wrong: ', error);
            showToast('error', 'Something went wrong. Please try again');
        } finally {
            setLoading(false);
        }
    }

    const actionTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className="flex flex-row gap-2 items-center">
                    <Button tooltip={rowData?.isActive ? 'Inactivate' : 'Activate'} onClick={() => handleActivate(rowData)} tooltipOptions={{ position: 'top' }} icon={rowData?.isActive ? 'pi pi-minus' : "pi pi-check"} style={{ height: '30px', width: '35px' }} size="small" className={`${rowData?.isActive ? 'bg-red-700 hover:text-red-700 hover:border-red-700 border-red-700 text-white' : 'bg-green-800 hover:text-green-800 hover:border-green-800 border-green-800 text-white'} hover:bg-white `} />
                    <Button tooltip={rowData?.isPublished ? 'Unpublish' : 'Publish'} onClick={() => handlePublish(rowData)} tooltipOptions={{ position: 'top' }} icon={rowData?.isPublished ? 'pi pi-minus-circle' : "pi pi-check-circle"} style={{ height: '30px', width: '35px' }} size="small" className={`${rowData?.isPublished ? 'bg-red-700 hover:text-red-700 hover:border-red-700 border-red-700 text-white' : 'bg-green-800 hover:text-green-800 hover:border-green-800 border-green-800 text-white'} hover:bg-white `} />
                    <Button tooltip="Edit Activity" onClick={() => {  setEditModalVisible(true); setSelectedActivity(rowData) }} tooltipOptions={{ position: 'top' }} icon="pi pi-user-edit" style={{ height: '30px', width: '35px' }} size="small" className="bg-custom-blue text-white hover:bg-white hover:text-custom-blue border-custom-blue" />
                    <Button tooltip="Images" onClick={() => { setSelectedActivity(rowData); setImageVisible(true) }} tooltipOptions={{ position: 'top' }} icon="pi pi-image" style={{ height: '30px', width: '35px' }} size="small" className="bg-custom-blue text-white hover:bg-white hover:text-custom-blue border-custom-blue" />
                    <Button tooltip="Documents" onClick={() => { setSelectedActivity(rowData); setDocumentVisible(true) }} tooltipOptions={{ position: 'top' }} icon="pi pi-file" style={{ height: '30px', width: '35px' }} size="small" className="bg-custom-blue text-white hover:bg-white hover:text-custom-blue border-custom-blue" />
                    <Button icon="pi pi-delete-left" tooltip="Delete Activity" tooltipOptions={{ position: 'top' }} onClick={() => confirm2(rowData)} style={{ height: '30px', width: '35px' }} size="small" className="bg-red-700 hover:bg-white hover:text-red-700 border-red-700 hover:border-red-700" />
                </div>
            </React.Fragment>
        );
    };

    const publishedBodyTemplate = (rowData) => {
        if (rowData?.isPublished) {
            return <Tag severity="success" value="Published"></Tag>
        } else {
            return <Tag severity="danger" value="Unpublished"></Tag>
        }
    }

    return (
        <PagesLayout
            items={items}
            title='Activities'
        >
            <ConfirmDialog />
            <DataTable
                value={applyPaginationAndFilters()}
                header={header}
                lazy
                dataKey="id"
                paginator
                first={lazyState.first}
                totalRecords={totalRecords}
                rows={lazyState.rows}
                onPage={onPage}
                onSort={onSort}
                sortField={lazyState.sortField}
                sortOrder={lazyState.sortOrder}
                onFilter={onFilter}
                filters={lazyState.filters}
                // filterDisplay="row"
                loading={loading}
                ref={dt}
                scrollable
                scrollHeight="60vh"
                selectionMode="single"
                rowsPerPageOptions={[5, 10, 25]}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Activities"
            >
                <Column field="title" header="Title" sortable filter filterPlaceholder="Search" />
                <Column field="description" header="Description" sortable filter filterPlaceholder="Search" />
                <Column field="content" header="Content" sortable filter filterPlaceholder="Search" />
                <Column field="startDate" header="Start Date" sortable filter body={rowData => ConvertDateTime(rowData?.startDate)} filterPlaceholder="Search" />
                <Column field="endDate" header="End Date" sortable filter body={rowData => ConvertDateTime(rowData?.endDate)} filterPlaceholder="Search" />
                <Column field="allDay" header="All Day"/>
                <Column field="keyPoints" header="Key Points" sortable filter filterPlaceholder="Search" />
                <Column field="activitytype.name" header="Type" sortable filter filterPlaceholder="Search" />
                <Column field="isActive" header="Active" body={handleActive} />
                <Column field="isPublished" header="Published" body={rowData => publishedBodyTemplate(rowData)} />
                <Column field="createdAt" header="Date Created" body={rowData => ConvertDateTime(rowData?.createdAt)} sortable filter filterPlaceholder="Search" />
                <Column frozen className="actionwidth" body={actionTemplate} />
            </DataTable>
            {addModalVisible && <AddActivity
                visible={addModalVisible}
                setVisible={setAddModalVisible}
                setActivities={setActivities}
                setTotalRecords={setTotalRecords}
            />}
            {editModalVisible && <EditActivity
                visible={editModalVisible}
                setVisible={setEditModalVisible}
                setActivities={setActivities}
                Activity={selectedActivity}
            />}
            {documentVisible && <ActivityDocuments
                visible={documentVisible}
                setVisible={setDocumentVisible}
                Activity={selectedActivity}
                setActivities={setActivities}
            />}
            {imageVisible && <ActivityImages
                visible={imageVisible}
                setVisible={setImageVisible}
                Activity={selectedActivity}
                setActivities={setActivities}
            />}
        </PagesLayout>

    );
}

export default Activities;
