import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
// import { buttonsPermissions } from "../../Helpers/PermissionsHelpers";
import PagesLayout from "../../layout/pagesLayout";
// import { showToast } from "../../App";
// import moment from "moment";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import AddRole from "./AddRole";
import { DeleteRole, GetRoles } from "../../network/Roles.network";
import { showToast } from "../../App";
import EditRole from "./EditRole";
// import { deleteRole, getRoles } from "../../network/Roles.network";

const items = [{ label: "Roles" }];

function Roles(props) {
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [roles, setRoles] = useState(null);
    const [addModalVisible, setAddModalVisible] = useState(false)
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [role, setRole] = useState({})
    const [editModalVisible, setEditModalVisible] = useState(false)

    const dt = useRef(null);

    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
        filters: {
            name: { value: "", matchMode: "contains" },
        },
    });

    const fetchRoles = async () => {
        try {
            setLoading(true)
            await GetRoles().then(response => {
                if (response?.status === 200) {
                    setRoles(response?.data?.data)
                    setTotalRecords(response?.data?.data?.length)
                }
            })
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchRoles()
    }, []);


    const applyPaginationAndFilters = () => {
        let filteredData = roles;

        if (!roles) {
            return []; // Return an empty array if tableRecords data is null
        }
        // Apply filters
        Object.keys(lazyState.filters).forEach((key) => {
            const filterValue = lazyState.filters[key].value;
            if (filterValue) {
                filteredData = filteredData.filter((item) =>
                    String(item[key]).toLowerCase().includes(filterValue.toLowerCase())
                );
            }
        });

        // Apply sorting
        if (lazyState.sortField && lazyState.sortOrder) {
            const sortMultiplier = lazyState.sortOrder === 1 ? 1 : -1;
            filteredData.sort((a, b) => {
                const valueA = a[lazyState.sortField];
                const valueB = b[lazyState.sortField];
                if (valueA < valueB) {
                    return -1 * sortMultiplier;
                } else if (valueA > valueB) {
                    return 1 * sortMultiplier;
                }
                return 0;
            });
        }

        // Apply pagination
        const { first, rows } = lazyState;
        const paginatedData = filteredData.slice(first, first + rows);

        return paginatedData;
    };

    const onPage = (event) => {
        setlazyState(event);
    };
    const onSort = (event) => {
        setlazyState(event);
    };

    const onFilter = (event) => {
        event["first"] = 0;
        setlazyState(event);
    };


    const header = (
            <div className="flex items-center justify-end gap-2">
                <Button
                    label='Create'
                    type="button"
                    icon="pi pi-user-plus"
                    onClick={() => setAddModalVisible(true)}
                    tooltip="Add New Role"
                    className="bg-custom-blue text-white hover:bg-white hover:text-custom-blue border-custom-blue"
                    tooltipOptions={{ position: 'top' }}
                />
            </div>
    );



    const actionTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className="flex flex-row gap-2 items-center">
                <Button tooltip="Edit User" onClick={() => { setRole(rowData); setEditModalVisible(true) }} tooltipOptions={{ position: 'top' }} icon="pi pi-user-edit" style={{ height: '30px', width: '35px' }} size="small" className="bg-custom-blue text-white hover:bg-white hover:text-custom-blue border-custom-blue" />
                    <Button icon="pi pi-delete-left" tooltip="Delete Role" tooltipOptions={{ position: 'top' }} onClick={() => confirm2(rowData)} style={{ height: '30px', width: '35px' }} size="small" className="bg-red-700 hover:bg-white hover:text-red-700 border-red-700 hover:border-red-700" />
                </div>
            </React.Fragment>
        );
    };


    const accept = async (id) => {
        await DeleteRole(id).then(response => {
            if (response?.status === 200) {
                const newRoles = roles.filter(role => role?.id !== id)
                setRoles(newRoles)
                return showToast('success', 'Role deleted successfully')
            } else {
                return showToast('error', 'Error deleting role. Please try again!')
            }
        }).catch((error) => {
            console.error('Error deleting role:', error);
            showToast('error', 'An error occurred while deleting role.');
        })
    }

    const confirm2 = (data) => {
        const acceptWithUserData = () => {
            // Pass the data to the accept function
            accept(data?.id);
        };
        confirmDialog({
            message: 'Do you want to delete this role?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-danger',
            accept: acceptWithUserData
        });
    };

    // const handlePermissions = (rowData) => {

    // }

    return (
        <PagesLayout
            items={items}
            title='Roles'
        >
            <ConfirmDialog />
            <DataTable
                value={applyPaginationAndFilters()}
                header={header}
                lazy
                // filterDisplay="row"
                dataKey="id"
                paginator
                first={lazyState.first}
                totalRecords={totalRecords}
                rows={10}
                onPage={onPage}
                onSort={onSort}
                sortField={lazyState.sortField}
                sortOrder={lazyState.sortOrder}
                onFilter={onFilter}
                filters={lazyState.filters}
                loading={loading}
                ref={dt}
                scrollable
                scrollHeight="60vh"
                selectionMode="single"
                selection={selectedProduct}
                onSelectionChange={(e) => setSelectedProduct(e.value)}
            >
                <Column field="name" header="Role Name" />
                {/* <Column field="privileges?.model" header="Permissions" body={handlePermissions} /> */}
                {/* <Column field="name" header="Priviliges"  /> */}
                <Column frozen className="actionwidth" body={actionTemplate} />
            </DataTable>
            {addModalVisible && <AddRole
                visible={addModalVisible}
                setVisible={setAddModalVisible}
                setRoles={setRoles}
            />}
            {editModalVisible && <EditRole
                visible={editModalVisible}
                setVisible={setEditModalVisible}
                roles={role}
                setRoles={setRoles}
            />}
        </PagesLayout>

    );
}

export default Roles;
