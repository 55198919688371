import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import React, { useRef, useState } from 'react'
import { showToast } from '../../App';
import { DeleteDocument, UploadDocuments } from '../../network/Upload.network';

const ActivityDocuments = (props) => {
    const dt = useRef(null);
    const [documents, setDocuments] = useState(props?.Activity?.documents)
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(props?.Activity?.documents?.length || 0);
    const [addModalVisible, setAddModalVisible] = useState(false)
    const [selectedDoc, setSelectedDoc] = useState()
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
        filters: {
            title: { value: "", matchMode: "contains" },
            description: { value: "", matchMode: "contains" },
            startDate: { value: "", matchMode: "contains" },
            endDate: { value: "", matchMode: "contains" },
            keyPoints: { value: "", matchMode: "contains" },
            createdAt: { value: "", matchMode: "contains" },
        },
    });

    const applyPaginationAndFilters = () => {
        let filteredData = documents;

        if (!documents) {
            return []; // Return an empty array if tableRecords data is null
        }
        // Apply filters
        Object.keys(lazyState.filters).forEach((key) => {
            const filterValue = lazyState.filters[key].value;
            if (filterValue) {
                filteredData = filteredData.filter((item) =>
                    String(item[key]).toLowerCase().includes(filterValue.toLowerCase())
                );
            }
        });

        // Apply sorting
        if (lazyState.sortField && lazyState.sortOrder) {
            const sortMultiplier = lazyState.sortOrder === 1 ? 1 : -1;
            filteredData.sort((a, b) => {
                const valueA = a[lazyState.sortField];
                const valueB = b[lazyState.sortField];
                if (valueA < valueB) {
                    return -1 * sortMultiplier;
                } else if (valueA > valueB) {
                    return 1 * sortMultiplier;
                }
                return 0;
            });
        }

        // Apply pagination
        const { first, rows } = lazyState;
        const paginatedData = filteredData.slice(first, first + rows);

        return paginatedData;
    };

    const onPage = (event) => {
        setlazyState(event);
    };
    const onSort = (event) => {
        setlazyState(event);
    };

    const onFilter = (event) => {
        event["first"] = 0;
        setlazyState(event);
    };

    const handleDocument = async () => {
        if (!selectedDoc) {
            return showToast('warn', 'No document added')
        }
        setLoading(true)
        const formData = new FormData()
        formData.append('file', selectedDoc)
        formData.append('type', 'activity')
        console.log(props)
        await UploadDocuments(props?.Activity?.id, formData).then(response => {
            if (response?.status === 200) {
                setDocuments(prev => [...prev, response?.data?.data])
                props.setActivities(prevActivities => 
                    prevActivities.map(activity =>
                      activity.id === props?.Activity?.id
                        ? { ...activity, documents: [...activity?.documents, response?.data?.data] }
                        : activity
                    )
                  );
                setTotalRecords(prev => prev + 1)
                showToast('success', 'Document added successfully')
                setAddModalVisible(false)
                setSelectedDoc(null)
            }
        }).catch(err => {
            console.error('Error uploading document', err);
            showToast('error', 'Something went wrong. Please try again!');
        })
            .finally(() => {
                setLoading(false)
            });
    }

    const header = (
        <div className="flex items-center justify-end">
            <Button
                label='Add'
                type="button"
                icon="pi pi-plus"
                onClick={() => setAddModalVisible(true)}
                tooltip="Add New Event"
                className="bg-custom-blue text-white hover:bg-white hover:text-custom-blue border-custom-blue"
                tooltipOptions={{ position: 'top' }}
            />
        </div>
    );

    const header1 = (
        <h1 className='text-center text-2xl text-custom-blue'>Actiity Documents</h1>
    )

    const header2 = (
        <h1 className='text-center text-2xl text-custom-blue'>Add Document</h1>
    )

    const accept = async (data) => {
        let obj = {
            type: 'activity',
            fileUrl: data
        }
        await DeleteDocument(props?.Activity?.id, obj).then(response => {
            if (response?.status === 200) {
                setDocuments(prevImages => prevImages.filter(doc => doc !== data));
                props.setActivities(prevActivities => 
                    prevActivities.map(activity =>
                      activity.id === props?.Activity?.id
                        ? { ...activity, documents: activity?.documents.filter(doc => doc !== data) }
                        : activity
                    )
                  );
                  setTotalRecords(prev => prev - 1)
                return showToast('success', 'Document deleted successfully')
            } else {
                return showToast('error', 'Error deleting Document. Please try again!')
            }
        }).catch((error) => {
            console.error('Error deleting Document:', error);
            showToast('error', 'An error occurred while deleting Document.');
        })
    }

    const confirm2 = (data) => {
        const acceptWithUserData = () => {
            // Pass the data to the accept function
            accept(data);
        };
        confirmDialog({
            message: 'Do you want to delete this document?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-danger',
            accept: acceptWithUserData
        });
    };

    const actionTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className='flex flex-row items-center gap-2'>
                    {/* <Button icon="pi pi-file-export" tooltip="Download Document" tooltipOptions={{ position: 'top' }} onClick={() => confirm3(rowData)} style={{ height: '30px', width: '35px' }} size="small" className="bg-red-700 hover:bg-white hover:text-red-700 border-red-700 hover:border-red-700" /> */}
                    <Button icon="pi pi-delete-left" tooltip="Delete Document" tooltipOptions={{ position: 'top' }} onClick={() => confirm2(rowData)} style={{ height: '30px', width: '35px' }} size="small" className="bg-red-700 hover:bg-white hover:text-red-700 border-red-700 hover:border-red-700" />
                </div>
            </React.Fragment>
        );
    };

    return (
        <Dialog
            header={header1}
            visible={props.visible}
            onHide={() => props.setVisible(false)}
            className='w-auto'
        >
            <DataTable
                value={applyPaginationAndFilters()}
                header={header}
                lazy
                dataKey="id"
                paginator
                first={lazyState.first}
                totalRecords={totalRecords}
                rows={lazyState.rows}
                onPage={onPage}
                onSort={onSort}
                sortField={lazyState.sortField}
                sortOrder={lazyState.sortOrder}
                onFilter={onFilter}
                filters={lazyState.filters}
                filterDisplay="row"
                loading={loading}
                ref={dt}
                scrollable
                scrollHeight="60vh"
                selectionMode="single"
                rowsPerPageOptions={[5, 10, 25]}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} documents"
            >
                <Column field="document" body={rowData => rowData} header="Document" />
                <Column frozen className="actionwidth" body={actionTemplate} />
            </DataTable>
            {addModalVisible &&
                <Dialog
                    header={header2}
                    visible={addModalVisible}
                    onHide={() => setAddModalVisible(false)}
                    className='w-auto '
                >
                    <div className="flex flex-col gap-2 w-full">
                        <label htmlFor="address">Document</label>
                        <span>
                            <input type="file" onChange={(e) => setSelectedDoc(e.target.files[0])} />
                        </span>
                        <div className='w-full'>
                            <Button
                                type="button"
                                label={loading ? 'Adding...' : 'Add Document'}
                                className="bg-custom-blue text-white hover:bg-light-blue-2 hover:text-custom-blue border-custom-blue w-full"
                                disabled={loading}
                                onClick={handleDocument}
                            />
                        </div>
                    </div>
                </Dialog>
            }
        </Dialog>
    )
}

export default ActivityDocuments