import { PrivateApiCall } from "../config/interceptors";
import { errorCatch } from "../helpers/error";

async function GetUserTypes() {
    const apiCall = await PrivateApiCall.get("/admin/usertype")
      .then((response) => response)
      .catch((error) => errorCatch(error, "Error getting users"));
    return apiCall;
  }

  export {
    GetUserTypes
  }
