import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import PagesLayout from "../../layout/pagesLayout";
import { showToast } from "../../App";
import { Tag } from "primereact/tag";
import AddMerchant from "./AddMerchant"
import EditMerchant from "./EditMerchant"
import { ActivateMerchant, DownloadQrCode, GetMerchants, RemoveMerchant, VerifyMerchant } from "../../network/Merchants.network";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import * as XLSX from 'xlsx';
import { ConvertDateTime } from "../../helpers/convertDateTime";
import { classNames } from "primereact/utils";
import MerchantImages from "./MerchantImages";

const items = [{ label: "Merchants" }];

function Merchants(props) {
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [merchants, setMerchants] = useState([]);
    const [addModalVisible, setAddModalVisible] = useState(false)
    const [editModalVisible, setEditModalVisible] = useState(false)
    const [merchant, setMerchant] = useState()
    const [imageVisible, setImageVisible] = useState(false)

    const dt = useRef(null);

    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
        filters: {
            firstName: { value: "", matchMode: "contains" },
            lastName: { value: "", matchMode: "contains" },
            merchantName: { value: "", matchMode: "contains" },
            email: { value: "", matchMode: "contains" },
            addressPublic: { value: "", matchMode: "contains" },
            phone: { value: "", matchMode: "contains" },
            website: { value: "", matchMode: "contains" },
            createdAt: { value: "", matchMode: "contains" },
        },
    });

    const fetchMerchants = async () => {
        try {
            setLoading(true)
            await GetMerchants().then(response => {
                if (response?.status === 200) {
                    setMerchants(response?.data?.data)
                    setTotalRecords(response?.data?.data?.length)
                } else {
                    showToast('error', 'Error Getting Merchants')
                }
            })
        } catch (error) {
            showToast('error', 'Error Getting Merchants')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchMerchants()
    }, []);


    const applyPaginationAndFilters = () => {
        let filteredData = merchants;

        if (!merchants) {
            return []; // Return an empty array if tableRecords data is null
        }
        // Apply filters
        Object.keys(lazyState.filters).forEach((key) => {
            const filterValue = lazyState.filters[key].value;
            if (filterValue) {
                filteredData = filteredData.filter((item) =>
                    String(item[key]).toLowerCase().includes(filterValue.toLowerCase())
                );
            }
        });

        // Apply sorting
        if (lazyState.sortField && lazyState.sortOrder) {
            const sortMultiplier = lazyState.sortOrder === 1 ? 1 : -1;
            filteredData.sort((a, b) => {
                const valueA = a[lazyState.sortField];
                const valueB = b[lazyState.sortField];
                if (valueA < valueB) {
                    return -1 * sortMultiplier;
                } else if (valueA > valueB) {
                    return 1 * sortMultiplier;
                }
                return 0;
            });
        }

        // Apply pagination
        const { first, rows } = lazyState;
        const paginatedData = filteredData.slice(first, first + rows);

        return paginatedData;
    };

    const onPage = (event) => {
        setlazyState(event);
    };
    const onSort = (event) => {
        setlazyState(event);
    };

    const exportXLSX = () => {
        const exportData = merchants;
        if (exportData?.length > 0) {
            const exportedColumns = exportColumns.map((col) => col.dataKey);
            const exportedRows = exportData.map((merchant) =>
                exportedColumns.map((col) => {
                    const value = merchant[col];
                    return value;
                })
            );
            const worksheetData = [exportedColumns, ...exportedRows];

            const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Merchants');

            // Generate a unique file name based on the current timestamp
            const fileName = 'merchants.xlsx';

            XLSX.writeFile(workbook, fileName);
        } else {
            showToast('error', 'No data found')
        }
    };

    //Columns and data to be displayed in exported PDF and Excel files
    const cols = [
        { field: "firstName", header: "First Name" },
        { field: "lastName", header: "Last Name" },
        { field: "merchantName", header: "Merchant Name" },
        { field: "email", header: "Email" },
        { field: "phone", header: "Phone" },
        { field: "website", header: "Website" },
        { field: "createdAt", header: "Created At" }
    ];

    const exportColumns = cols.map((col) => ({
        title: col.header,
        dataKey: col.field,
    }));


    const header = (
        <div className="flex items-center justify-between gap-2">
            <Button
                type="button"
                icon="pi pi-file"
                severity="primary"
                onClick={() => exportXLSX()}
                className='bg-green-800 text-white hover:bg-white hover:text-green-800 border border-green-800'
                tooltip="Export to Excel"
            />
            <Button
                label='Create'
                type="button"
                icon="pi pi-user-plus"
                onClick={() => setAddModalVisible(true)}
                tooltip="Add New Merchant"
                className="bg-custom-blue text-white hover:bg-white hover:text-custom-blue border-custom-blue"
                tooltipOptions={{ position: 'top' }}
            />
        </div>
    );


    const handleActive = (rowData) => {
        if (rowData?.isActive) {
            return <Tag severity="success" value="Active"></Tag>
        } else {
            return <Tag severity="danger" value="Inactive"></Tag>
        }
    }

    const verifiedBodyTemplate = (rowData) => {
        return <i className={classNames('pi', { 'true-icon pi-check-circle': rowData?.isVerified, 'false-icon pi-times-circle': !rowData?.isVerified })}></i>;
    };

    const accept = async (id) => {
        await RemoveMerchant(id).then(response => {
            if (response?.status === 200) {
                const newMerchants = merchants.filter(admin => admin?.id !== id)
                setMerchants(newMerchants)
                setTotalRecords(prev => prev - 1)
                return showToast('success', 'Merchant deleted successfully')
            } else {
                return showToast('error', 'Error deleting Merchant. Please try again.')
            }
        }).catch((error) => {
            console.error('Error deleting Merchant:', error);
            showToast('error', 'An error occurred while deleting Merchant.');
        })
    }

    const confirm2 = (data) => {
        const acceptWithUserData = () => {
            // Pass the data to the accept function
            accept(data?.id);
        };
        confirmDialog({
            message: 'Do you want to delete this merchant?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-danger',
            accept: acceptWithUserData
        });
    };

    const handleVerify = async (rowData) => {
        try {
            setLoading(true);
            let data = { isVerified: rowData?.isVerified ? false : true }
            const response = await VerifyMerchant(rowData?.id, data);
            if (response?.status === 200) {
                setMerchants(prev => {
                    return prev.map(merchant => {
                        if (merchant.id === rowData?.id) {
                            return {
                                ...merchant, // Spread the existing admin data
                                verifiedAt: response?.data?.verifiedAt === null ? null : response?.data?.verifiedAt, // Overwrite with the updated data
                                isVerified: response?.data?.isVerified
                            };
                        } else {
                            return merchant;
                        }
                    });
                });
                showToast('success', `${rowData?.isVerified ? 'Merchant Unverified successfully!' : 'Merchant verified sucessfully!'}`)
            } else {
                showToast('error', 'Error verifying merchant');
            }
        } catch (error) {
            console.error('Something went wrong: ', error);
            showToast('error', 'Something went wrong. Please try again');
        } finally {
            setLoading(false);
        }
    }

    const handleActivate = async (rowData) => {
        try {
            setLoading(true);
            let data = { isActive: rowData?.isActive ? false : true }
            const response = await ActivateMerchant(rowData?.id, data);
            if (response?.status === 200) {
                setMerchants(prev => {
                    return prev.map(merchant => {
                        if (merchant.id === rowData?.id) {
                            return {
                                ...merchant, // Spread the existing admin data
                                isActive: response?.data?.data?.isActive
                            };
                        } else {
                            return merchant;
                        }
                    });
                });
                showToast('success', `${rowData?.isActive ? 'Merchant Inactivated successfully!' : 'Merchant activated sucessfully!'}`)
            } else {
                showToast('error', 'Error activating merchant');
            }
        } catch (error) {
            console.error('Something went wrong: ', error);
            showToast('error', 'Something went wrong. Please try again');
        } finally {
            setLoading(false);
        }
    }

    const handleQr = async (rowData) => {
        try {
            const response = await DownloadQrCode(rowData?.id);
            if (response?.status === 200) {
            // Remove the "data:image/png;base64," part
            const base64Data = response?.data?.qrcode?.replace(/^data:image\/png;base64,/, "");

            // Convert Base64 string to a byte array
            const byteCharacters = atob(base64Data);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);

            // Create a Blob from the byte array
            const blob = new Blob([byteArray], { type: 'image/png' });

            // Create a URL for the Blob
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `qrcode-${rowData.merchantName}.png`); // Set the download attribute with a filename
            document.body.appendChild(link);
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
        }else{
            return showToast('error', 'Error while download qrcode');
        }
        } catch (err) {
            console.error('Error getting qrcode ', err)
        }
    };


    const actionTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className="flex flex-row gap-2 items-center">
                    <Button tooltip='Download QrCode' onClick={() => handleQr(rowData)} tooltipOptions={{ position: 'top' }} icon='pi pi-download' style={{ height: '30px', width: '35px' }} size="small" />
                    <Button tooltip={rowData?.isActive ? 'set Inactive' : 'Activate'} onClick={() => handleActivate(rowData)} tooltipOptions={{ position: 'top' }} icon={rowData?.isActive ? 'pi pi-minus' : "pi pi-check"} style={{ height: '30px', width: '35px' }} size="small" className={`${rowData?.isActive ? 'bg-red-700 hover:text-red-700 hover:border-red-700 border-red-700 text-white' : 'bg-green-800 hover:text-green-800 hover:border-green-800 border-green-800 text-white'} hover:bg-white `} />
                    <Button tooltip={rowData?.isVerified ? 'Unverify' : 'Verify'} onClick={() => handleVerify(rowData)} tooltipOptions={{ position: 'top' }} icon={rowData?.isVerified ? 'pi pi-minus-circle' : "pi pi-check-circle"} style={{ height: '30px', width: '35px' }} size="small" className={`${rowData?.isVerified ? 'bg-red-700 hover:text-red-700 hover:border-red-700 border-red-700 text-white' : 'bg-green-800 hover:text-green-800 hover:border-green-800 border-green-800 text-white'} hover:bg-white `} />
                    <Button tooltip="Edit Merchant" onClick={() => { setEditModalVisible(true); setMerchant(rowData) }} tooltipOptions={{ position: 'top' }} icon="pi pi-user-edit" style={{ height: '30px', width: '35px' }} size="small" className="bg-custom-blue text-white hover:bg-white hover:text-custom-blue border-custom-blue" />
                    <Button tooltip="Images" onClick={() => { setMerchant(rowData); setImageVisible(true) }} tooltipOptions={{ position: 'top' }} icon="pi pi-image" style={{ height: '30px', width: '35px' }} size="small" className="bg-custom-blue text-white hover:bg-white hover:text-custom-blue border-custom-blue" />
                    <Button icon="pi pi-delete-left" tooltip="Delete Merchant" tooltipOptions={{ position: 'top' }} onClick={() => confirm2(rowData)} style={{ height: '30px', width: '35px' }} size="small" className="bg-red-700 hover:bg-white hover:text-red-700 border-red-700 hover:border-red-700" />
                </div>
            </React.Fragment>
        );
    };

    const onFilter = (event) => {
        event["first"] = 0;
        setlazyState(event);
    };

    return (
        <PagesLayout
            items={items}
            title='Merchants'>
            <ConfirmDialog />
            <DataTable
                value={applyPaginationAndFilters()}
                header={header}
                lazy
                dataKey="id"
                filterDisplay="row"
                paginator
                first={lazyState.first}
                totalRecords={totalRecords}
                rows={lazyState.rows}
                onPage={onPage}
                onSort={onSort}
                sortField={lazyState.sortField}
                sortOrder={lazyState.sortOrder}
                onFilter={onFilter}
                filters={lazyState.filters}
                loading={loading}
                ref={dt}
                scrollable
                scrollHeight="60vh"
                selectionMode="single"
                rowsPerPageOptions={[5, 10, 25]}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} merchants"
                selection={merchant}
                onSelectionChange={(e) => setMerchant(e.value)}
            >
                <Column selectionMode="single" exportable={false}></Column>
                <Column field="firstname" header="First Name" sortable filter filterPlaceholder="Search" />
                <Column field="lastname" header="Last Name" sortable filter filterPlaceholder="Search" />
                <Column field="title" header="Merchant Name" sortable filter filterPlaceholder="Search" />
                <Column field="email" header="Email" sortable filter filterPlaceholder="Search" />
                <Column field="phone" header="Phone Number" sortable filter filterPlaceholder="Search" />
                <Column field="merchantdetail.website" header="Website" sortable filter filterPlaceholder="Search" />
                <Column field="merchantdetail.workingHours.weekday.startTime" header="weekday Start Time" sortable filter filterPlaceholder="Search" />
                <Column field="merchantdetail.workingHours.weekday.endTime" header="weekday End Time" sortable filter filterPlaceholder="Search" />
                <Column field="merchantdetail.workingHours.weekend.startTime" header="weekend Start Time" sortable filter filterPlaceholder="Search" />
                <Column field="merchantdetail.workingHours.weekend.endTime" header="weekend End Time" sortable filter filterPlaceholder="Search" />
                <Column field="isActive" header="Status" body={handleActive} />
                <Column field="isVerified" header="Verified" body={verifiedBodyTemplate} />
                <Column field="createdAt" header="Created Date" body={(rowData) => rowData?.createdAt ? ConvertDateTime(rowData?.createdAt) : null} />
                <Column body={actionTemplate} />
            </DataTable>
            {addModalVisible && <AddMerchant
                visible={addModalVisible}
                setVisible={setAddModalVisible}
                setMerchants={setMerchants}
                setTotalRecords={setTotalRecords}
            />}
            {editModalVisible && <EditMerchant
                visible={editModalVisible}
                setVisible={setEditModalVisible}
                merchant={merchant}
                setMerchant={setMerchant}
                setMerchants={setMerchants}
            />}
            {imageVisible && <MerchantImages
                visible={imageVisible}
                setVisible={setImageVisible}
                merchant={merchant}
                setMerchants={setMerchants}
            />}
        </PagesLayout>

    );
}

export default Merchants;
