import { Avatar } from 'primereact/avatar'
import { Button } from 'primereact/button'
import { Sidebar } from 'primereact/sidebar'
import { TieredMenu } from 'primereact/tieredmenu'
import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage'
import LOGO from '../../assets/logo.png'
import { jwtDecode } from 'jwt-decode'
import ChangePassword from '../ChangePassword/ChangePassword'

const Topbar = () => {
    const [visible, setVisible] = useState(false);
    const navigate = useNavigate()
    const location = useLocation();
    const [activePage, setActivePage] = useState(null)
    const user = jwtDecode(secureLocalStorage.getItem('token'))
    const [showChangePass, setShowChangePass] = useState(false)

    useEffect(() => {
        setActivePage(location.pathname);
    }, [location.pathname]);

    const links = [
        {
            label: "Dashboard",
            icon: "pi pi-home",
            link: '/'
        },
        {
            label: "Roles",
            icon: "pi pi-id-card",
            link: '/roles'
        },
        {
            label: "Admins",
            icon: "pi pi-users",
            link: '/admins'
        },
        {
            label: "Leads",
            icon: "pi pi-users",
            link: '/leads'
        },
        {
            label: "Users",
            icon: "pi pi-users",
            link: '/users'
        },
        {
            label: "Merchants",
            icon: "pi pi-shop",
            link: '/merchants'
        },
        {
            label: "Activities",
            icon: "pi pi-sitemap",
            link: '/activities'
        },
        // {
        //     label: "Events",
        //     icon: "pi pi-sitemap",
        //     link: '/events'
        // },
        // {
        //     label: "Offers",
        //     icon: "pi pi-gift",
        //     link: '/offers'
        // },
    ]

    const handlePass = () => {

    }

    const menu = useRef(null);
    const items = [
        {
            label: 'Change Password',
            icon: 'pi pi-pencil',
            command: () => setShowChangePass(true)
        },
        {
            label: 'Logout',
            icon: 'pi pi-sign-out',
            command: () => { secureLocalStorage.clear(); navigate('/login') }
        },
    ];


    return (
        <div className='bg-white py-5 px-5 border flex flex-row justify-between items-center'>
            <Sidebar
                visible={visible}
                onHide={() => setVisible(false)}
                content={({ closeIconRef, hide }) => (
                    <div className="min-h-screen flex relative">
                        <div className="surface-section h-screen block flex-shrink-0 absolute lg:static left-0 top-0 z-1 border-right-1 surface-border select-none w-full">
                            <div className="flex flex-col h-full gap-5 items-center w-full">
                                <div className="flex items-center flex-row px-4 pt-3 gap-2 cursor-pointer" onClick={() => navigate('/')}>
                                    <img src={LOGO} alt='logo' />
                                    {/* <h1 className='text-2xl font-bold'>PartOf</h1> */}
                                </div>
                                <div className="overflow-y-auto text-center justify-center w-full">
                                    <ul className='px-3'>
                                        {links?.map((link, index) => (
                                            <li key={index} className={`flex text-center rounded items-center cursor-pointer p-3 mb-1 ${activePage === link.link ? 'text-white bg-custom-blue' : 'bg-white text-custom-blue'} hover:text-white hover:bg-custom-blue transition duration-150 ease-in-out w-full`}>
                                                <Link to={link?.link} className="flex flex-row items-center justify-start w-full">
                                                    <i className={`${link?.icon} mr-2 text-2xl`}></i>
                                                    <span className="font-semibold">{link?.label}</span>
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            >
            </Sidebar>
            <div className='flex flex-row gap-3 items-center'>
                <Button icon="pi pi-align-justify" style={{ height: '30px' }} className=' border-none bg-custom-blue hover:bg-light-blue-2 hover:text-custom-blue' onClick={() => setVisible(true)} />
                <h1 className='font-bold text-xl'>PartOf</h1>
            </div>
            <div className='relative'>
                <Avatar
                    label={user?.email[0]?.toUpperCase()}
                    style={{backgroundColor: '#26286e'}}
                    className='cursor-pointer text-white'
                    onClick={(e) => menu.current.toggle(e)}
                />
                <TieredMenu model={items} popup ref={menu} className='max-w-32 text-sm px-2 rounded' />
            </div>
            <ChangePassword visible={showChangePass} setVisible={setShowChangePass} user={user} />
        </div>
    )
}

export default Topbar