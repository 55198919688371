import React, { useEffect, useState } from 'react'
import { Chart } from 'primereact/chart';
import DashboardLayout from '../../layout/DashboardLayout';
import { GetTopOffers } from '../../network/Dashboard.network';

const OffersAnalytics = () => {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const [events, setEvents] = useState({});
    const [loading, setLoading] = useState(false)

    const fetchTopOffers = async () => {
        try {
            setLoading(true)
            await GetTopOffers().then(response => {
                if (response?.status === 200) {
                    const transformResult = response?.data?.data.reduce((acc, curr) => {
                        acc.offerNames.push(curr.title);
                        acc.countMembers.push(parseInt(curr?.participants?.length, 10)); // Ensure guestCount is a number
                        return acc;
                    }, { offerNames: [], countMembers: [] });
                    setEvents(transformResult)
                }
            })
        } catch (error) {
            console.error('Error fetching top events: ', error);
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchTopOffers()
    }, [])

    console.log()

    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const data = {
            labels: events?.offerNames,
            datasets: [
                {
                    data: events?.countMembers,
                    backgroundColor: [
                        documentStyle.getPropertyValue('--blue-500'),
                        documentStyle.getPropertyValue('--yellow-500'),
                        documentStyle.getPropertyValue('--green-500'),
                        documentStyle.getPropertyValue('--purple-500'),
                        documentStyle.getPropertyValue('--orange-500')
                    ],
                    hoverBackgroundColor: [
                        documentStyle.getPropertyValue('--blue-400'),
                        documentStyle.getPropertyValue('--yellow-400'),
                        documentStyle.getPropertyValue('--green-400'),
                        documentStyle.getPropertyValue('--purple-500'),
                        documentStyle.getPropertyValue('--orange-500')
                    ]
                }
            ]
        };
        const options = {
            cutout: '60%'
        };

        setChartData(data);
        setChartOptions(options);
    }, [events]);

    return (
        <DashboardLayout title="Top Offers by Members" handleClick={fetchTopOffers} loading={loading}>
            <div className='flex justify-center min-h-96'>
                <Chart type="doughnut" data={chartData} options={chartOptions} className="w-full min-h-96" />
            </div>
        </DashboardLayout>

    )
}

export default OffersAnalytics