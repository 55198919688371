import { ErrorMessage, Field, Formik } from 'formik';
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext';
import React, { useState, useEffect } from 'react'
import { Button } from 'primereact/button';
import { showToast } from '../../App';
import { UpdateActivity } from '../../network/Activity.network';
import { Calendar } from 'primereact/calendar';
// import { GetMerchants } from '../../network/Merchants.network';
import { MultiSelect } from 'primereact/multiselect';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { GetTypes } from '../../network/ActivityType.network';
import { GetUsers } from '../../network/Users.network';
import Loading from '../../components/Loading/Loading';
import Required from '../../components/Required/Required';

const EditActivity = (props) => {
    const [loading, setLoading] = useState(false)
    const [merchantOptions, setMerchantOptions] = useState([])
    const [typeOptions, setTypeOptions] = useState([])

    const header = (
        <h1 className='text-center text-2xl text-custom-blue'>Edit Activity</h1>
    )

    const getTypes = async () => {
        try {
            setLoading(true)
            await GetTypes().then(response => {
                if (response?.status === 200) {
                    setTypeOptions(response.data.data)
                }
            })
        } catch (error) {
            showToast('error', 'Error fetching Merchants.')
        } finally {
            setLoading(false)
        }
    }

    const getMerchants = async () => {
        try {
            setLoading(true)
            await GetUsers("false").then(response => {
                if (response?.status === 200) {
                    setMerchantOptions(response.data.data)
                }
            })
        } catch (error) {
            showToast('error', 'Error fetching Merchants.')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (props?.visible) {
            getMerchants()
            getTypes()         
        }
    }, [props?.visible])

    return (
        <Dialog
            header={header}
            visible={props?.visible}
            onHide={() => props?.setVisible(false)}
            className='w-auto'
        >
           <Formik
                initialValues={{
                    title: props?.Activity?.title,
                    content: props?.Activity?.content,
                    description: props?.Activity?.description,
                    startDate: props?.Activity?.startDate ? new Date(props?.Activity?.startDate) : null,
                    endDate: props?.Activity?.endDate ? new Date(props?.Activity?.endDate) : null,
                    allDay: props?.Activity?.allDay,
                    isActive: props?.Activity?.isActive,
                    merchantIds: props?.Activity?.coOrganizers?.map(co => co?.id),
                    keyPoints: props?.Activity?.keyPoints?.length > 0 ? props?.Activity.keyPoints.join(',') : null,
                    ownerId: props?.Activity?.owner?.id,
                    addMerchants: props?.Activity?.addMerchants,
                    typeId: props?.Activity?.activitytype?.id
                }}

                validate={values => {
                    const errors = {};
                    if (!values.title) {
                        errors.title = 'Title is required.';
                    }
                    if (!values.startDate) {
                        errors.startDate = 'Start date is required.';
                    }
                    if (!values.endDate) {
                        errors.endDate = 'End date is required.';
                    }
                    if (!values.ownerId) {
                        errors.ownerId = 'Organizer is required.';
                    }
                    if(!values.keyPoints) {
                        errors.keyPoints = 'keyPoints is required.';
                    }
                    if (values.keyPoints && values.keyPoints.includes(',')) {
                        const keyPointsArray = values.keyPoints?.split(',')?.map(item => item.trim());
                        const invalidKeyPoint = keyPointsArray?.some(point => point?.length === 0);
                        if (invalidKeyPoint) {
                            errors.keyPoints = 'Invalid format';
                        }
                    }
                    return errors;
                }}
                onSubmit={async (values, { setSubmitting }) => {
                    setSubmitting(true)
                    console.log(values)
                    values.keyPoints = values.keyPoints?.split(',')
                    await UpdateActivity(values, props?.Activity?.id).then(response => {
                        if (response?.status === 200) {
                            props?.setActivities(prev => {
                                return prev.map(activity => {
                                if (activity.id === props?.Activity.id) {
                                    return response?.data?.data;
                                } else {
                                    return activity;
                                }
                                });
                            });
                            showToast('success', 'Activity updated successfully.')
                            props?.setVisible(false)
                        } else {
                            return showToast('error', response?.data?.error ? response?.data?.error : 'Error updating Activity.')
                        }
                    }).catch(error => {
                        console.error('Error submitting form:', error);
                        showToast('error', 'An error occurred while submitting the form.');
                    })
                        .finally(() => {
                            setSubmitting(false)
                        })
                }}
            >
                {({
                    values,
                    handleChange,
                    handleSubmit,
                    isSubmitting
                }) => (
                    <form onSubmit={handleSubmit} className='text-custom-blue flex flex-col gap-8 w-full'>
                        <div className='flex flex-row items-center gap-5 justify-between w-full'>
                            <div className='flex flex-col gap-1 w-full'>
                                <label htmlFor='title'>Title <Required /></label>
                                <Field as={InputText} id='title' name='title' placeholder='Title' />
                                <ErrorMessage name='title' component='div' className='text-red-500' />
                            </div>
                            <div className='flex flex-col gap-1 w-full'>
                                <label htmlFor='content'>Content</label>
                                <Field as={InputText} id='content' name='content' placeholder='Content' />
                                <ErrorMessage name='content' component='div' className='text-red-500' />
                            </div>
                            <div className='flex flex-col gap-1 w-full'>
                                <label htmlFor='description'>Description</label>
                                <Field as={InputText} id='description' name='description' placeholder='Description' />
                                <ErrorMessage name='description' component='div' className='text-red-500' />
                            </div>
                        </div>
                        <div className='flex flex-row items-center gap-5 justify-between w-full'>
                            <div className='flex flex-col gap-1 w-full'>
                                <label htmlFor='keyPoints'>Key Points</label>
                                <Field as={InputText} id='keyPoints' name='keyPoints' placeholder='keyPoints' />
                                <ErrorMessage name='keyPoints' component='div' className='text-red-500' />
                            </div>
                            <div className='flex flex-col gap-1 w-full'>
                                <label htmlFor='startDate'>Start Date <Required /></label>
                                <Field as={Calendar} id='startDate' name='startDate' showTime hourFormat='12' placeholder='Start Date' />
                                <ErrorMessage name='startDate' component='div' className='text-red-500' />
                            </div>
                            <div className='flex flex-col gap-1 w-full'>
                                <label htmlFor='endDate'>End Date <Required /></label>
                                <Field as={Calendar} id='endDate' name='endDate' showTime hourFormat='12' placeholder='End Date' />
                                <ErrorMessage name='endDate' component='div' className='text-red-500' />
                            </div>
                        </div>
                        <div className='flex flex-row items-center gap-5 justify-between w-full'>
                            <div className='flex flex-col gap-1 w-full'>
                                <label htmlFor='ownerId'>Owner <Required /></label>
                                <Field as={Dropdown} filter id='ownerId' name='ownerId' options={merchantOptions} optionLabel='title' optionValue='id' placeholder='Select Organizer' />
                                <ErrorMessage name='ownerId' component='div' className='text-red-500' />
                            </div>
                            <div className='flex flex-col gap-1 w-full'>
                                <label htmlFor='merchantIds'>Responsable</label>
                                <Field as={MultiSelect} filter id='merchantIds' name='merchantIds' disabled={!values.ownerId} options={merchantOptions.filter(merchant => merchant.id !== values.ownerId)} optionLabel='title' optionValue='id' placeholder='Select CoOrganizers' />
                                <ErrorMessage name='merchantIds' component='div' className='text-red-500' />
                            </div>
                            <div className='flex flex-col gap-1 w-full'>
                                <label htmlFor='typeId'>Type <Required /></label>
                                <Field as={Dropdown} id='typeId' name='typeId' options={typeOptions} optionLabel='name' optionValue='id' placeholder='Select Activity Type' />
                                <ErrorMessage name='typeId' component='div' className='text-red-500' />
                            </div>
                        </div>
                        <div className='flex flex-row items-center gap-5 justify-between w-full'>
                            <div className='flex flex-1 flex-row gap-1 w-full'>
                                <Checkbox inputId='allDay' name='allDay' onChange={handleChange} checked={values.allDay} />
                                <label htmlFor='allDay'>All Day</label>
                            </div>
                            <div className='flex flex-1 flex-row gap-1 w-full'>
                                <Checkbox inputId='isActive' name='isActive' onChange={handleChange} checked={values.isActive} />
                                <label htmlFor='isActive'>Active</label>
                            </div>
                            <div className='flex-1'></div>
                        </div>
                        <Button
                            type="submit"
                            label={isSubmitting ? 'Updating...' : 'Update'}
                            className="bg-custom-blue text-white hover:bg-light-blue-2 hover:text-custom-blue border-custom-blue w-full"
                            disabled={isSubmitting}
                        />
                    </form>
                )}
            </Formik>
        </Dialog>
    )
}

export default EditActivity