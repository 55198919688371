import axios from "axios";

import secureLocalStorage from "react-secure-storage";
import keys from "../Keys";

export const PrivateApiCall = axios.create({
  baseURL: keys.API_BASE,
});

PrivateApiCall.interceptors.request.use(
  (req) => {
    const token = secureLocalStorage.getItem("token");
    req.headers.Authorization = `Bearer ${token}`;
    return req;
  },
  (err) => {
    throw err;
  }
);

PrivateApiCall.interceptors.response?.use(
  (res) => res,
  (err) => {
    if (err?.reponse) {
      if (err?.response?.status === 401) {
        secureLocalStorage.clear();
        window.location.href = `${window.location.origin}/login`;
      }
    }
    throw err;
  }
);

export const PublicApiCall = axios.create({
  baseURL: keys.API_BASE,
});

PublicApiCall.interceptors.request.use(
  (req) => {
    return req
  },
  (err) => {
    throw err;
  }
);

PublicApiCall.interceptors.response?.use(
  (res) => res,
  (err) => {
    throw err;
  }
);

