import { ProgressSpinner } from 'primereact/progressspinner';
import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import Topbar from '../components/Topbar';
import NotFound from '../pages/404';
import Admins from '../pages/Admins/Admins';
import Dashboard from '../pages/Dashboard/Dashboard';
import Events from '../pages/Events/Events';
import Merchants from '../pages/Merchants/Merchants';
import Offers from '../pages/Offers/Offers';
import Users from '../pages/Users/Users';
import Roles from '../pages/Roles/Roles';
import Leads from '../pages/Leads/Leads';
import Activities from '../pages/Activities/Activities';

const lazyRoutes = [
    { path: '/', component: Dashboard, permission: 'Dashboard' },
    { path: '/roles', component: Roles, permission: 'Roles' },
    { path: '/admins', component: Admins, permission: 'Admin' },
    { path: '/leads', component: Leads, permission: 'Leads' },
    { path: '/users', component: Users, permission: 'Users' },
    { path: '/events', component: Events, permission: 'Events' },
    { path: '/activities', component: Activities, permission: 'Merchants' },
    { path: '/merchants', component: Merchants, permission: 'Merchants' },
    { path: '/offers', component: Offers, permission: 'Offers' }
]

const Layout = () => {
    return (
        <div className='flex flex-col h-auto min-h-screen text-custom-blue'>
            <div className='bg-light-blue-2 h-auto min-h-screen'>
            <Topbar />
                <Suspense fallback={<ProgressSpinner style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />}>
                    <Routes>
                        {lazyRoutes.map(route => (
                            <Route
                                key={route.path}
                                path={route.path}
                                element={<route.component />}
                            />

                        ))}
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </Suspense>
            </div>
        </div>
    );
};

export default Layout;