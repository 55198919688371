import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import PagesLayout from "../../layout/pagesLayout";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { showToast } from "../../App";
import { GetLeads } from "../../network/Leads.network";

const items = [{ label: "Leads" }];

function Leads(props) {
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [leads, setLeads] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);

    const dt = useRef(null);

    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
        filters: {
            phone: { value: "", matchMode: "contains" },
            activitytitle: { value: "", matchMode: "contains" },
            typename: { value: "", matchMode: "contains" },
        },
    });

    const fetchRoles = async () => {
        try {
            setLoading(true)
            await GetLeads().then(response => {
                if (response?.status === 200) {
                    setLeads(response?.data?.data)
                    setTotalRecords(response?.data?.data?.length)
                }
            })
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchRoles()
    }, []);


    const applyPaginationAndFilters = () => {
        let filteredData = leads;

        if (!leads) {
            return []; // Return an empty array if tableRecords data is null
        }
        // Apply filters
        Object.keys(lazyState.filters).forEach((key) => {
            const filterValue = lazyState.filters[key].value;
            if (filterValue) {
                filteredData = filteredData.filter((item) =>
                    String(item[key]).toLowerCase().includes(filterValue.toLowerCase())
                );
            }
        });

        // Apply sorting
        if (lazyState.sortField && lazyState.sortOrder) {
            const sortMultiplier = lazyState.sortOrder === 1 ? 1 : -1;
            filteredData.sort((a, b) => {
                const valueA = a[lazyState.sortField];
                const valueB = b[lazyState.sortField];
                if (valueA < valueB) {
                    return -1 * sortMultiplier;
                } else if (valueA > valueB) {
                    return 1 * sortMultiplier;
                }
                return 0;
            });
        }

        // Apply pagination
        const { first, rows } = lazyState;
        const paginatedData = filteredData.slice(first, first + rows);

        return paginatedData;
    };

    const onPage = (event) => {
        setlazyState(event);
    };
    const onSort = (event) => {
        setlazyState(event);
    };

    const onFilter = (event) => {
        event["first"] = 0;
        setlazyState(event);
    };


    return (
        <PagesLayout
            items={items}
            title='Leads'
        >
            <ConfirmDialog />
            <DataTable
                value={applyPaginationAndFilters()}
                lazy
                // filterDisplay="row"
                dataKey="id"
                paginator
                first={lazyState.first}
                totalRecords={totalRecords}
                rows={10}
                onPage={onPage}
                onSort={onSort}
                sortField={lazyState.sortField}
                sortOrder={lazyState.sortOrder}
                onFilter={onFilter}
                filters={lazyState.filters}
                loading={loading}
                ref={dt}
                scrollable
                scrollHeight="60vh"
                selectionMode="single"
                selection={selectedProduct}
                onSelectionChange={(e) => setSelectedProduct(e.value)}
            >
                <Column field="phone" header="Phone" sortable filter filterPlaceholder="Search" />
                <Column field="activitytitle" header="Title" sortable filter filterPlaceholder="Search" />
                <Column field="typename" header="Type" sortable filter filterPlaceholder="Search" />
            </DataTable>
        </PagesLayout>

    );
}

export default Leads;
