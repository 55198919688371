import { PrivateApiCall } from "../config/interceptors";
import { errorCatch } from "../helpers/error";

async function GetTypes() {
    const apiCall = await PrivateApiCall.get("/admin/activitytype")
      .then((response) => response)
      .catch((error) => errorCatch(error, "Error getting Types"));
    return apiCall;
  }

  export {
    GetTypes
  }