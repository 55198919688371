import { ErrorMessage, Field, Formik } from 'formik';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useEffect, useState } from 'react';
import { showToast } from '../../App';
import { UpdateMerchant } from '../../network/Merchants.network';
import "../../styles.css";
import Required from '../../components/Required/Required';
import { InputTextarea } from 'primereact/inputtextarea';
import { GetUserTypes } from '../../network/UserTypes.network';
import { formatTime } from '../../helpers/convertToTimeOnly';
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

const AddMerchant = (props) => {
    const [loading, setLoading] = useState(false)
    const [types, setTypes] = useState(false)

    const header = (
        <h1 className='text-center text-2xl text-custom-blue'>Edit Merchant</h1>
    )

    useEffect(() => {
        const fetchTypes = async () => {
            try {
                setLoading(true)
                await GetUserTypes().then(response => {
                    if (response?.status === 200) {
                        setTypes(response?.data?.data?.filter(fil => fil?.id !== 1))
                    } else {
                        showToast('error', 'Error Getting Types')
                    }
                })
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false)
            }
        }
        fetchTypes()
    }, [])
    return (
        <Dialog
            header={header}
            visible={props.visible}
            onHide={() => props.setVisible(false)}
            className={loading ? 'w-96 h-96' : 'w-auto'}
        >
            <Formik
                initialValues={{
                    firstname: props.merchant?.firstname || '',
                    lastname: props.merchant?.lastname || '',
                    email: props.merchant?.email || '',
                    phone: props.merchant?.phone || '',
                    title: props.merchant?.title || '',
                    bio: props.merchant?.bio || '',
                    website: props.merchant?.merchantdetail?.website || '',
                    typeId: props.merchant?.defaultType || null,
                    address: props.merchant?.address ? props.merchant?.address : '',
                    weekdaystart: props.merchant?.merchantdetail ? new Date(props.merchant?.merchantdetail?.workingHours?.weekday?.startTime) : new Date(),
                    weekdayend: props.merchant?.merchantdetail ? new Date(props.merchant?.merchantdetail?.workingHours?.weekday?.endTime) : new Date(),
                    weekendstart: props.merchant?.merchantdetail ? new Date(props.merchant?.merchantdetail?.workingHours?.weekend?.startTime) : new Date(),
                    weekendend: props.merchant?.merchantdetail ? new Date(props.merchant?.merchantdetail?.workingHours?.weekend?.endTime) : new Date()
                }}
                validate={values => {
                    const errors = {};
                    if (!values.firstname) {
                        errors.firstname = 'First name is required.';
                    }
                    if (!values.weekdaystart) {
                        errors.weekdaystart = 'Weekday from time is required'
                    }
                    if (!values.weekdayend) {
                        errors.weekdayend = 'Weekday to time is required'
                    }
                    if (!values.weekendstart) {
                        errors.weekendstart = 'Weekend from time is required'
                    }
                    if (!values.weekendend) {
                        errors.weekendend = 'Weekdend to time is required'
                    }
                    if (!values.lastname) {
                        errors.lastname = 'Last name is required.';
                    }

                    if (!values.phone) {
                        errors.phone = 'Phone is required.';
                    }

                    // if (!isValidPhoneNumber(values?.phone)) {
                    //     errors.phone = 'Not a valid phone number.';
                    // }

                    if (!values.title) {
                        errors.title = 'Merchant name is required'
                    }

                    if (!values.typeId) {
                        errors.typeId = 'Type is required'
                    }

                    if (!values.email) {
                        errors.email = 'Email is required';
                    } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                    ) {
                        errors.email = 'Invalid email address';
                    }
                    return errors;
                }}
                onSubmit={async (values, { setSubmitting }) => {
                    setSubmitting(true)
                    if(!isValidPhoneNumber(values.phone)){
                        return showToast('error', 'Not valid phone number!')
                    }
                    values.workingHours = {
                        weekday: {
                            startTime: formatTime(values.weekdaystart),
                            endTime: formatTime(values.weekdayend)
                        },
                        weekend: {
                            startTime: formatTime(values.weekendstart),
                            endTime: formatTime(values.weekendend)
                        }
                    }
                    delete values.weekdaystart
                    delete values.weekdayend
                    delete values.weekendstart
                    delete values.weekendend
                    await UpdateMerchant(values, props.merchant.id).then(response => {
                        if (response?.status === 200) {
                            props.setMerchants(prev => {
                                return prev.map(merchant => {
                                    if (merchant.id === props.merchant.id) {
                                        return response?.data?.data;
                                    } else {
                                        return merchant;
                                    }
                                });
                            });
                            showToast('success', 'Merchant updated successfully.')
                            props.setVisible(false)
                        } else {
                            return showToast('error', 'Error updating merchant.')
                        }
                    }).catch(error => {
                        console.error('Something went wrong: ', error)
                        showToast('error', 'An error occurred while submitting the form.');
                    })
                        .finally(() => {
                            setSubmitting(false)
                        });
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                }) => (
                    <>
                        {loading ? <ProgressSpinner style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                            :
                            <form onSubmit={handleSubmit} className='text-custom-blue flex flex-col items-center gap-6 mt-6 justify-between w-full'>
                                <div className='flex lg:flex-row flex-col gap-5 justify-between w-full'>
                                    {/* First Name */}
                                    <div className="flex flex-col gap-2 w-full">
                                        <span className="p-float-label">
                                            <Field as={InputText} id="firstname" name="firstname" className="w-full" />
                                            <label htmlFor="firstname">First Name <Required /></label>
                                        </span>
                                        <ErrorMessage name="firstname" component="div" className="text-red-500" />
                                    </div>
                                    {/* Last Name */}
                                    <div className="flex flex-col gap-2 w-full">
                                        <span className="p-float-label">
                                            <Field as={InputText} id="lastname" name="lastname" className="w-full" />
                                            <label htmlFor="lastname">Last name <Required /></label>
                                        </span>
                                        <ErrorMessage name="lastname" component="div" className="text-red-500" />
                                    </div>
                                    {/* Email */}
                                    <div className="flex flex-col gap-2 w-full">
                                        <span className="p-float-label">
                                            <Field as={InputText} id="email" name="email" className="w-full" />
                                            <label htmlFor="email">Email <Required /></label>
                                        </span>
                                        <ErrorMessage name="email" component="div" className="text-red-500" />
                                    </div>
                                </div>

                                <div className='flex lg:flex-row flex-col gap-5 items-center w-full'>
                                    {/* Phone Number */}
                                    <div className="flex flex-col gap-2 w-full">
                                        <span className="p-float-label">
                                            <PhoneInput
                                                id="phone"
                                                defaultCountry="CH"
                                                value={values.phone}
                                                onChange={(e) => setFieldValue('phone', e)}// Set Formik value
                                                className="w-full p-3 border rounded-lg text-lg"
                                            />
                                        </span>
                                        <ErrorMessage name="phone" component="div" className="text-red-500" />
                                    </div>
                                    {/* Merchant Name */}
                                    <div className="flex flex-col gap-2 w-full">
                                        <span className="p-float-label">
                                            <Field as={InputText} id="title" label={"title"} name="title" value={values?.title} onChange={handleChange} className="w-full" />
                                            <label htmlFor="title">Merchant Name <Required /></label>
                                        </span>
                                        <ErrorMessage name="title" component="div" className="text-red-500" />
                                    </div>

                                    {/* Address */}
                                    <div className="flex flex-col gap-2 w-full">
                                        <span className="p-float-label">
                                            <Field as={InputText} id="address" label={"address"} name="address" value={values?.address} onChange={handleChange} className="w-full" />
                                            <label htmlFor="address">Address</label>
                                        </span>
                                        <ErrorMessage name="address" component="div" className="text-red-500" />
                                    </div>
                                </div>
                                <div className='flex lg:flex-row flex-col gap-5 items-center justify-between w-full'>
                                    {/* Website */}
                                    <div className="flex flex-col gap-2 w-full">
                                        <span className="p-float-label">
                                            <Field as={InputText} id="website" label={"website"} name="website" value={values?.website} onChange={handleChange} className="w-full" />
                                            <label htmlFor="website">Website</label>
                                        </span>
                                        <ErrorMessage name="website" component="div" className="text-red-500" />
                                    </div>
                                    {/* Type */}
                                    <div className="flex flex-col gap-2 w-full">
                                        <span className="p-float-label">
                                            <Field
                                                as={Dropdown}
                                                id="typeId"
                                                name="typeId"
                                                value={values.typeId}
                                                options={types || null}
                                                optionLabel="name"
                                                optionValue="id"
                                                onChange={handleChange}
                                                placeholder="Select typeId"
                                                className="w-full"
                                            />
                                            <label htmlFor="typeId">Type<Required /></label>
                                        </span>
                                        <ErrorMessage name="type" component="div" className="text-red-500" />
                                    </div>
                                    <div className="flex flex-col gap-2 w-full">
                                        <span className="p-float-label">
                                            <Field as={InputTextarea} id="bio" label={"bio"} name="bio" value={values?.bio} onChange={handleChange} className="w-full" />
                                            <label htmlFor="bio">Bio</label>
                                        </span>
                                        <ErrorMessage name="bio" component="div" className="text-red-500" />
                                    </div>
                                    {/* Location */}
                                    {/* <div className="flex flex-col w-full">
                                    <span className="p-float-label">
                                        <Field as={InputText} id="Location" label={"Location"} name="Location" className="w-full" />
                                        <label htmlFor="Location">Location</label>
                                    </span>
                                    <ErrorMessage name="Location" component="div" className="text-red-500" />
                                </div> */}
                                </div>
                                <label className='text-blue-950 font-semibold text-xl'>Working Hours</label>
                                <div className='flex lg:flex-row flex-col w-full'>
                                    <div className='flex-1 flex flex-col gap-6'>
                                        <label className='text-center font-semibold'>Weekdays</label>
                                        <div className='flex lg:flex-row flex-col gap-5 items-center w-full'>
                                            {/* Weekday Start Time */}
                                            <div className="flex flex-col gap-2">
                                                <span className="p-float-label">
                                                    <Field as={Calendar} timeOnly={true} hourFormat="12" id="weekdaystart" label={"weekdaystart"} name="weekdaystart" />
                                                    <label htmlFor="weekdaystart">Start Time <Required /></label>
                                                </span>
                                                <ErrorMessage name="weekdaystart" component="div" className="text-red-500" />
                                            </div>
                                            {/* Weekday End Time */}
                                            <div className="flex flex-col gap-2">
                                                <span className="p-float-label">
                                                    <Field as={Calendar} timeOnly={true} hourFormat="12" id="weekdayend" label={"weekdayend"} name="weekdayend" />
                                                    <label htmlFor="weekdayend">End Time <Required /></label>
                                                </span>
                                                <ErrorMessage name="weekdayend" component="div" className="text-red-500" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex-1 flex flex-col gap-6'>
                                        <label className='text-center font-semibold'>Weekends</label>
                                        <div className='flex lg:flex-row flex-col gap-5 items-center w-full'>
                                            {/* Weekday Start Time */}
                                            <div className="flex flex-col gap-2">
                                                <span className="p-float-label">
                                                    <Field as={Calendar} timeOnly={true} hourFormat="12" id="weekendstart" label={"weekendstart"} name="weekendstart" />
                                                    <label htmlFor="weekendstart">Start Time <Required /></label>
                                                </span>
                                                <ErrorMessage name="weekendstart" component="div" className="text-red-500" />
                                            </div>
                                            {/* Weekday End Time */}
                                            <div className="flex flex-col gap-2">
                                                <span className="p-float-label">
                                                    <Field as={Calendar} timeOnly={true} hourFormat="12" id="weekendend" label={"weekendend"} name="weekendend" />
                                                    <label htmlFor="weekendend">End Time <Required /></label>
                                                </span>
                                                <ErrorMessage name="weekendend" component="div" className="text-red-500" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='w-full'>
                                    <Button
                                        type="submit"
                                        label='Submit'
                                        className="bg-custom-blue text-white hover:bg-light-blue-2 hover:text-custom-blue border-custom-blue w-full"
                                        disabled={isSubmitting}
                                    />
                                </div>
                            </form>}
                    </>
                )}
            </Formik>

        </Dialog>
    )
}

export default AddMerchant