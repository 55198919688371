import { ErrorMessage, Field, Formik } from 'formik';
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react'
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { showToast } from '../../App';
import { GetRoles } from '../../network/Roles.network';
import { UpdateAdmin } from '../../network/Admins.network';
import { Checkbox } from 'primereact/checkbox';
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

const EditAdmin = (props) => {
    console.log(props)
    const [roles, setRoles] = useState([])

    const header = (
        <h1 className='text-center text-2xl text-custom-blue'>Edit Admin</h1>
    )

    console.log(props)

    useEffect(() => {
        const fetchRoles = async () => {
            await GetRoles().then(response => {
                if (response?.status === 200) {
                    setRoles(response.data.data)
                }
            })
        }
        fetchRoles()
    }, [])

    return (
        <Dialog
            header={header}
            visible={props.visible}
            onHide={() => props.setVisible(false)}
            className='w-auto'
        >
            <Formik
                initialValues={{
                    firstname: props?.admin?.firstname,
                    lastname: props?.admin?.lastname,
                    email: props?.admin?.email,
                    phone: props?.admin?.phone,
                    roleid: props?.admin?.role.id,
                    isActive: props?.admin?.isActive
                }}
                validate={values => {
                    const errors = {};
                    if (!values.firstname) {
                        errors.firstname = 'First name is required.';
                    }
                    if (!values.lastname) {
                        errors.lastname = 'Last name is required.';
                    }

                    if (!values.phone) {
                        errors.phone = 'Phone is required.';
                    }
                    if (!values.roleid) {
                        errors.roleid = 'Role is required.';
                    }
                    if (!values.email) {
                        errors.email = 'Email is required';
                    } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                    ) {
                        errors.email = 'Invalid email address';
                    }
                    return errors;
                }}
                onSubmit={async (values, { setSubmitting }) => {
                    if (!isValidPhoneNumber(values.phone)) {
                        return showToast('error', 'Not valid phone number!')
                    }
                    await UpdateAdmin(values, props.admin.id).then(response => {
                        if (response?.status === 200) {
                            props.setAdmins(prev => {
                                return prev.map(admin => {
                                    if (admin.id === props.admin.id) {
                                        return response?.data?.data;
                                    } else {
                                        return admin;
                                    }
                                });
                            });
                            showToast('success', 'Admin updating successfully!')
                            props.setVisible(false)
                        } else {
                            return showToast('error', response?.data?.error ? response?.data?.error : 'Error updating admin.')
                        }

                    }).catch(error => {
                        console.error('Error submitting form:', error);
                        showToast('error', 'An error occurred while submitting the form.');
                    })
                        .finally(() => {
                            setSubmitting(false);
                        });

                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    handleSubmit,
                    isSubmitting,
                }) => (
                    <form onSubmit={handleSubmit} className='text-custom-blue flex flex-col justify-center items-center gap-8 mt-6 w-full'>
                        {console.log(errors)}
                        <div className='flex lg:flex-row flex-col gap-5 w-full'>
                            <div className="flex flex-col gap-2">
                                <span className="p-float-label">
                                    <Field as={InputText} id="firstname" name="firstname" value={values?.firstname} onChange={handleChange} />
                                    <label htmlFor="firstname">First name</label>
                                </span>
                                <ErrorMessage name="firstname" component="div" className="text-red-500" />
                            </div>
                            <div className="flex flex-col gap-2">
                                <span className="p-float-label">
                                    <Field as={InputText} id="lastname" name="lastname" value={values?.lastname} onChange={handleChange} />
                                    <label htmlFor="lastname">Last name</label>
                                </span>
                                <ErrorMessage name="lastname" component="div" className="text-red-500" />
                            </div>
                            <div className="flex flex-col gap-2">
                                <span className="p-float-label">
                                    <Field as={InputText} id="email" name="email" value={values?.email} onChange={handleChange} />
                                    <label htmlFor="email">Email</label>
                                </span>
                                <ErrorMessage name="email" component="div" className="text-red-500" />
                            </div>
                        </div>
                        <div className='flex lg:flex-row flex-col gap-5 items-center w-full'>
                            <div className="flex flex-col gap-2">
                                <PhoneInput
                                    id="phone"
                                    defaultCountry="CH"
                                    value={values.phone}
                                    onChange={(e) => setFieldValue('phone', e)}// Set Formik value
                                    className="w-full p-3 border rounded-lg text-lg"
                                />
                                <ErrorMessage name="phone" component="div" className="text-red-500" />
                            </div>
                            <div className="flex flex-col gap-2">
                                <Field
                                    as={Dropdown}
                                    id="roleid"
                                    name="roleid"
                                    value={values.roleid}
                                    options={roles || null}
                                    optionLabel="name"
                                    optionValue="id"
                                    onChange={handleChange}
                                    placeholder="Select a Role"
                                />
                                <ErrorMessage name="roleid" component="div" className="text-red-500" />
                            </div>
                            <div className="flex align-items-center">
                                <Checkbox inputId="isActive" name="isActive" variant='filled' value="isActive" className='border border-custom-blue rounded-lg solid' onChange={handleChange} checked={values.isActive} />
                                <label htmlFor="isActive" className="ml-2">Active</label>
                            </div>
                        </div>
                        <Button
                            type="submit"
                            label={isSubmitting ? 'Updating...' : 'Update'}
                            className="bg-custom-blue text-white hover:bg-light-blue-2 hover:text-custom-blue border-custom-blue w-full"
                            disabled={isSubmitting}
                        />
                    </form>
                )}
            </Formik>
        </Dialog>
    )
}

export default EditAdmin