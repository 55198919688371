import { ErrorMessage, Field, Formik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import React from 'react';
import { showToast } from '../../App';
import Required from '../../components/Required/Required';
import { CreateUser } from '../../network/Users.network';
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

const AddUser = (props) => {
    const header = (
        <h1 className='text-center text-2xl text-custom-blue'>Add User</h1>
    )

    return (
        <Dialog
            header={header}
            visible={props.visible}
            onHide={() => props.setVisible(false)}
            className='w-auto'
        >
            <Formik
                initialValues={{
                    firstname: '',
                    lastname: '',
                    email: '',
                    phone: '',
                    title: '',
                    bio: '',
                    isPhonePrivate: false,
                    isEmailPrivate: false
                }}

                validate={values => {
                    const errors = {};
                    if (!values.firstname) {
                        errors.firstname = 'First name is required.';
                    }
                    if (!values.lastname) {
                        errors.lastname = 'Last name is required.';
                    }

                    if (!values.phone) {
                        errors.phone = 'Phone is required.';
                    }
                    if (!values.email) {
                        errors.email = 'Email is required';
                    } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                    ) {
                        errors.email = 'Invalid email address';
                    }

                    return errors;
                }}
                onSubmit={async (values, { setSubmitting }) => {
                    setSubmitting(true)
                    if(!isValidPhoneNumber(values.phone)){
                        return showToast('error', 'Not valid phone number!')
                    }
                    await CreateUser(values).then(response => {
                        if (response?.status === 200) {
                            const newUser = response?.data?.data
                            props.setUsers(prevUsers => [...prevUsers, newUser])
                            props.setTotalRecords(prevTotalRecords => prevTotalRecords + 1)
                            showToast('success', 'User created successfully.')
                            props.setVisible(false)
                        } else {
                            return showToast('error', response?.data?.error ? response?.data?.error : 'Error creating user.')
                        }
                    }).catch(error => {
                        console.error('Error submitting form:', error);
                        showToast('error', 'An error occurred while submitting the form.');
                    })
                        .finally(() => {
                            setSubmitting(false)
                        });
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                }) => (
                    <form onSubmit={handleSubmit} className='text-custom-blue flex flex-col justify-center items-center gap-6 mt-6 w-full'>
                        <div className='flex lg:flex-row flex-col gap-5 w-full'>
                            <div className="flex flex-col gap-2 w-full">
                                <span className="p-float-label">
                                    <Field as={InputText} id="firstname" name="firstname" className="w-full" />
                                    <label htmlFor="firstname">First name <Required /></label>
                                </span>
                                <ErrorMessage name="firstname" component="div" className="text-red-500" />
                            </div>
                            <div className="flex flex-col gap-2 w-full">
                                <span className="p-float-label">
                                    <Field as={InputText} id="lastname" name="lastname" className="w-full" />
                                    <label htmlFor="lastname">Last name <Required /></label>
                                </span>
                                <ErrorMessage name="lastname" component="div" className="text-red-500" />
                            </div>
                            <div className="flex flex-col gap-2 w-full">
                                <span className="p-float-label">
                                    <Field as={InputText} id="email" name="email" className="w-full" />
                                    <label htmlFor="email">Email <Required /></label>
                                </span>
                                <ErrorMessage name="email" component="div" className="text-red-500" />
                            </div>
                        </div>
                        <div className='flex lg:flex-row flex-col gap-5 items-center w-full'>
                            <div className="flex flex-col gap-2 w-full">
                                {/* Phone Number */}
                                <div className="flex flex-col gap-2 w-full">
                                    <PhoneInput
                                        id="phone"
                                        defaultCountry="CH"
                                        value={values.phone}
                                        onChange={(e) => setFieldValue('phone', e)}// Set Formik value
                                        className="w-full p-3 border rounded-lg text-lg"
                                    />
                                    <ErrorMessage name="phone" component="div" className="text-red-500" />
                                </div>
                            </div>
                            <div className="flex flex-col gap-2 w-full">
                                <span className="p-float-label">
                                    <Field as={InputText} id="title" label={"title"} name="title" className="w-full" />
                                    <label htmlFor="title">Title</label>
                                </span>
                                <ErrorMessage name="title" component="div" className="text-red-500" />
                            </div>
                            <div className="flex flex-col gap-2">
                                <span className="p-float-label">
                                    <Field as={InputTextarea} id="bio" label={"bio"} name="bio" />
                                    <label htmlFor="title">Bio</label>
                                </span>
                                <ErrorMessage name="title" component="div" className="text-red-500" />
                            </div>
                        </div>
                        <Button
                            type="submit"
                            label={isSubmitting ? 'Submitting...' : 'Submit'}
                            className="bg-custom-blue text-white hover:bg-light-blue-2 hover:text-custom-blue border-custom-blue w-full"
                            disabled={isSubmitting}
                        />
                    </form>
                )}
            </Formik>
        </Dialog>
    )
}

export default AddUser