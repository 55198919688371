import React, { useState, useEffect, useRef } from "react";
import 'primeicons/primeicons.css';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import PagesLayout from "../../layout/pagesLayout";
import { showToast } from "../../App";
import { Tag } from "primereact/tag";
import AddUsers from "./AddUser"
import EditUsers from "./EditUser"
import { ActivateUser, DeleteUser, GetUsers, VerifyUser } from "../../network/Users.network";
import * as XLSX from 'xlsx';
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { ConvertDateTime } from "../../helpers/convertDateTime";
import { classNames } from "primereact/utils";
import UserImages from "./UserImages";

const items = [{ label: "Users" }];

function Users(props) {
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [users, setUsers] = useState([]);
    const [addModalVisible, setAddModalVisible] = useState(false)
    const [editModalVisible, setEditModalVisible] = useState(false)
    const [selectedUser, setSelectedUser] = useState({})
    const [imageVisible, setImageVisible] = useState(false)
    const dt = useRef(null);

    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
        filters: {
            createdAt: { value: "", matchMode: "contains" },
            createddate: { value: "", matchMode: "contains" },
            firstName: { value: "", matchMode: "contains" },
            lastName: { value: "", matchMode: "contains" },
            title: { value: "", matchMode: "contains" },
            bio: { value: "", matchMode: "contains" },
            email: { value: "", matchMode: "contains" },
            addressPublic: { value: "", matchMode: "contains" },
            phone: { value: "", matchMode: "contains" },
        },
    });

    const exportXLSX = () => {
        const exportData = users;
        if (exportData?.length > 0) {
            const exportedColumns = exportColumns.map((col) => col.dataKey);
            const exportedRows = exportData.map((cdrmessages) =>
                exportedColumns.map((col) => {
                    const value = cdrmessages[col];
                    return value;
                })
            );
            const worksheetData = [exportedColumns, ...exportedRows];

            const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Members');

            // Generate a unique file name based on the current timestamp
            const fileName = 'members.xlsx';

            XLSX.writeFile(workbook, fileName);
        } else {
            showToast('error', 'No data found')
        }
    };

    //Columns and data to be displayed in exported PDF and Excel files
    const cols = [
        { field: "firstName", header: "First Name" },
        { field: "lastName", header: "Last Name" },
        { field: "title", header: "Title" },
        { field: "bio", header: "Bio" },
        { field: "email", header: "Email" },
        { field: "phone", header: "Phone" }

    ];

    const exportColumns = cols.map((col) => ({
        title: col.header,
        dataKey: col.field,
    }));

    const fetchUsers = async () => {
        try {
            setLoading(true)
            await GetUsers(true).then(response => {
                if (response?.status === 200) {
                    setUsers(response?.data?.data)
                    setTotalRecords(response?.data?.data?.length)
                } else {
                    showToast('error', 'Error Getting Users')
                }
            })
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchUsers()
    }, []);


    const applyPaginationAndFilters = () => {
        let filteredData = users;

        if (!users) {
            return []; // Return an empty array if tableRecords data is null
        }
        // Apply filters
        Object.keys(lazyState.filters).forEach((key) => {
            const filterValue = lazyState.filters[key].value;
            if (filterValue) {
                filteredData = filteredData.filter((item) =>
                    String(item[key]).toLowerCase().includes(filterValue.toLowerCase())
                );
            }
        });

        // Apply sorting
        if (lazyState.sortField && lazyState.sortOrder) {
            const sortMultiplier = lazyState.sortOrder === 1 ? 1 : -1;
            filteredData.sort((a, b) => {
                const valueA = a[lazyState.sortField];
                const valueB = b[lazyState.sortField];
                if (valueA < valueB) {
                    return -1 * sortMultiplier;
                } else if (valueA > valueB) {
                    return 1 * sortMultiplier;
                }
                return 0;
            });
        }

        // Apply pagination
        const { first, rows } = lazyState;
        const paginatedData = filteredData.slice(first, first + rows);

        return paginatedData;
    };

    const onPage = (event) => {
        setlazyState(event);
    };
    const onSort = (event) => {
        setlazyState(event);
    };

    const onFilter = (event) => {
        event["first"] = 0;
        setlazyState(event);
    };

    const handleVerify = async (rowData) => {
        try {
            setLoading(true);
            let data = { isVerified: rowData?.isVerified ? false : true }
            const response = await VerifyUser(rowData?.id, data);
            if (response?.status === 200) {
                setUsers(prev => {
                    return prev.map(user => {
                        if (user.id === rowData?.id) {
                            return {
                                ...user, // Spread the existing admin data
                                verifiedAt: response?.data?.verifiedAt === null ? null : response?.data?.verifiedAt, // Overwrite with the updated data
                                isVerified: response?.data?.isVerified
                            };
                        } else {
                            return user;
                        }
                    });
                });
                showToast('success', `${rowData?.isVerified ? 'User Unverified successfully!' : 'User verified sucessfully!'}`)
            } else {
                showToast('error', 'Error verifying admin');
            }
        } catch (error) {
            console.error('Something went wrong: ', error);
            showToast('error', 'Something went wrong. Please try again');
        } finally {
            setLoading(false);
        }
    }

    const handleActivate = async (rowData) => {
        try {
            setLoading(true);
            let data = { isActive: rowData?.isActive ? false : true }
            const response = await ActivateUser(rowData?.id, data);
            if (response?.status === 200) {
                setUsers(prev => {
                    return prev.map(user => {
                        if (user.id === rowData?.id) {
                            return {
                                ...user, // Spread the existing admin data
                                isActive: response?.data?.data?.isActive
                            };
                        } else {
                            return user;
                        }
                    });
                });
                showToast('success', `${rowData?.isActive ? 'User Inactivated successfully!' : 'User activated sucessfully!'}`)
            } else {
                showToast('error', 'Error activating user');
            }
        } catch (error) {
            console.error('Something went wrong: ', error);
            showToast('error', 'Something went wrong. Please try again');
        } finally {
            setLoading(false);
        }
    }


    const actionTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className="flex flex-row gap-2 items-center">
                    <Button tooltip={rowData?.isActive ? 'set Inactive' : 'Activate'} onClick={() => handleActivate(rowData)} tooltipOptions={{ position: 'top' }} icon={rowData?.isActive ? 'pi pi-minus' : "pi pi-check"} style={{ height: '30px', width: '35px' }} size="small" className={`${rowData?.isActive ? 'bg-red-700 hover:text-red-700 hover:border-red-700 border-red-700 text-white' : 'bg-green-800 hover:text-green-800 hover:border-green-800 border-green-800 text-white'} hover:bg-white `} />
                    <Button tooltip={rowData?.isVerified ? 'Unverify' : 'Verify'} onClick={() => handleVerify(rowData)} tooltipOptions={{ position: 'top' }} icon={rowData?.isVerified ? 'pi pi-minus-circle' : "pi pi-check-circle"} style={{ height: '30px', width: '35px' }} size="small" className={`${rowData?.isVerified ? 'bg-red-700 hover:text-red-700 hover:border-red-700 border-red-700 text-white' : 'bg-green-800 hover:text-green-800 hover:border-green-800 border-green-800 text-white'} hover:bg-white `} />
                    <Button tooltip="Edit User" onClick={() => { setEditModalVisible(true); setSelectedUser(rowData) }} tooltipOptions={{ position: 'top' }} icon="pi pi-user-edit" style={{ height: '30px', width: '35px' }} size="small" className="bg-custom-blue text-white hover:bg-white hover:text-custom-blue border-custom-blue" />
                    <Button tooltip="Images" onClick={() => { setSelectedUser(rowData); setImageVisible(true) }} tooltipOptions={{ position: 'top' }} icon="pi pi-image" style={{ height: '30px', width: '35px' }} size="small" className="bg-custom-blue text-white hover:bg-white hover:text-custom-blue border-custom-blue" />
                    <Button icon="pi pi-delete-left" tooltip="Delete User" tooltipOptions={{ position: 'top' }} onClick={() => confirm2(rowData)} style={{ height: '30px', width: '35px' }} size="small" className="bg-red-700 hover:bg-white hover:text-red-700 border-red-700 hover:border-red-700" />
                </div>
            </React.Fragment>
        );
    };


    const accept = async (id) => {
        await DeleteUser(id).then(response => {
            if (response?.status === 200) {
                const newUsers = users.filter(user => user?.id !== id)
                setUsers(newUsers)
                setTotalRecords(prev => prev - 1)
                return showToast('success', 'User deleted successfully')
            } else {
                return showToast('error', 'Error deleting User. Please try again!')
            }
        }).catch((error) => {
            console.error('Error deleting User:', error);
            showToast('error', 'An error occurred while deleting User.');
        })
    }

    const confirm2 = (data) => {
        const acceptWithUserData = () => {
            // Pass the data to the accept function
            accept(data?.id);
        };
        confirmDialog({
            message: 'Do you want to delete this user?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-danger',
            accept: acceptWithUserData
        });
    };


    const header = (
        <div className="flex items-center justify-between gap-2">
            <Button
                type="button"
                icon="pi pi-file"
                severity="primary"
                onClick={() => exportXLSX()}
                className='bg-green-800 text-white hover:bg-white hover:text-green-800 border border-green-800'
                tooltip="Export to Excel"
            />
            <Button
                label='Create'
                type="button"
                icon="pi pi-user-plus"
                onClick={() => setAddModalVisible(true)}
                tooltip="Add New User"
                className="bg-custom-blue text-white hover:bg-white hover:text-custom-blue border-custom-blue"
                tooltipOptions={{ position: 'top' }}
            />
        </div>
    );


    const handleActive = (rowData) => {
        if (rowData?.isActive) {
            return <Tag severity="success" value="Active"></Tag>
        } else {
            return <Tag severity="danger" value="Inactive"></Tag>
        }
    }

    const verifiedBodyTemplate = (rowData) => {
        return <i className={classNames('pi', { 'true-icon pi-check-circle': rowData?.isVerified, 'false-icon pi-times-circle': !rowData?.isVerified })}></i>;
    };

    return (
        <PagesLayout
            items={items}
            title='Users'
        >
            <ConfirmDialog />
            <DataTable
                value={applyPaginationAndFilters()}
                header={header}
                lazy
                filterDisplay="row"
                dataKey="id"
                paginator
                first={lazyState.first}
                totalRecords={totalRecords}
                rows={lazyState.rows}
                onPage={onPage}
                onSort={onSort}
                sortField={lazyState.sortField}
                sortOrder={lazyState.sortOrder}
                onFilter={onFilter}
                filters={lazyState.filters}
                loading={loading}
                ref={dt}
                scrollable
                scrollHeight="60vh"
                selectionMode="single"
                rowsPerPageOptions={[5, 10, 25]}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
            >
                <Column field="firstname" header="First Name" sortable filter filterPlaceholder="Search" />
                <Column field="lastname" header="Last Name" sortable filter filterPlaceholder="Search" />
                <Column field="title" header="Title" sortable filter filterPlaceholder="Search" />
                <Column field="bio" header="Bio" sortable filter filterPlaceholder="Search" />
                <Column field="email" header="Email" sortable filter filterPlaceholder="Search" />
                <Column field="phone" header="Phone Number" sortable filter filterPlaceholder="Search" />
                <Column field="isActive" header="Status" body={handleActive} />
                <Column field="isVerified" header="Verified" body={verifiedBodyTemplate} />
                <Column field="createdAt" body={(rowData) => rowData?.createdAt ? ConvertDateTime(rowData?.createdAt) : null} header="Create Date" />
                <Column frozen className="actionwidth" body={actionTemplate} />
            </DataTable>
            {addModalVisible && <AddUsers
                visible={addModalVisible}
                setVisible={setAddModalVisible}
                setUsers={setUsers}
                setTotalRecords={setTotalRecords}
            />}
            {editModalVisible && <EditUsers
                visible={editModalVisible}
                setVisible={setEditModalVisible}
                users={users}
                setUsers={setUsers}
                user={selectedUser}
            />}
            {imageVisible && <UserImages
                visible={imageVisible}
                setVisible={setImageVisible}
                user={selectedUser}
                setUsers={setUsers}
            />}
        </PagesLayout>
    );
}

export default Users;
