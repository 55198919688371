import { ErrorMessage, Field, Formik } from 'formik';
import { Dialog } from 'primereact/dialog'
import React from 'react'
import { CreateRole } from '../../network/Roles.network';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { showToast } from '../../App';

const AddRole = (props) => {
    const header = (
        <h1 className='text-center text-2xl text-custom-blue'>Add Role</h1>
    )

    return (
        <Dialog
            header={header}
            visible={props.visible}
            onHide={() => props.setVisible(false)}>
            <Formik
                initialValues={{ name: '' }}
                validate={values => {
                    const errors = {};
                    if (!values.name) {
                        errors.name = 'Role is required.';
                    }
                    return errors;
                }}
                onSubmit={async (values, { setSubmitting }) => {
                    await CreateRole(values).then(response => {
                        if (response?.status === 200) {
                            props.setRoles(prevRoles => [...prevRoles, response?.data])
                            showToast('success', 'Role created successfully!')
                            props.setVisible(false)
                        } else {
                            return showToast('error', response?.data?.error ? response?.data?.error : 'Error creating role.')
                        }
                    }).catch(error => {
                        console.error('Error submitting form:', error);
                        showToast('error', 'An error occurred while submitting the form.');
                    })
                        .finally(() => {
                            setSubmitting(false);
                        });
                }}
            >
                {({
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                }) => (
                    <form onSubmit={handleSubmit} className='text-custom-blue flex flex-col justify-center items-center gap-5 mt-5 w-full'>
                        <div className='flex flex-col gap-2 justify-center text-center'>
                                <span className="p-float-label">
                                    <Field as={InputText} id="name" name="name" value={values?.name} onChange={handleChange} />
                                    <label htmlFor="name">Role name</label>
                                </span>
                                <ErrorMessage name="name" component="div" className="text-red-500" />
                            </div>
                        <Button
                            type="submit"
                            label='Submit'
                            // icon="pi pi-user-plus"
                            className="bg-custom-blue text-white hover:bg-light-blue-2 hover:text-custom-blue border-custom-blue w-full"
                            disabled={isSubmitting} />
                    </form>
                )}
            </Formik>
        </Dialog>
    )
}

export default AddRole