import { PrivateApiCall } from "../config/interceptors";
import { errorCatch } from "../helpers/error";

async function GetRoles() {
    const apiCall = await PrivateApiCall.get("/admin/roles")
      .then((response) => response)
      .catch((error) => errorCatch(error, "Error getting roles"));
    return apiCall;
  }

  async function DeleteRole(id) {
    const apiCall = await PrivateApiCall.delete(`/admin/roles/${id}`)
      .then((response) => response)
      .catch((error) => errorCatch(error, "Error deleting roles"));
    return apiCall;
  }

  async function CreateRole(data) {
    const apiCall = await PrivateApiCall.post("/admin/roles", data)
      .then((response) => response)
      .catch((error) => errorCatch(error, "Error deleting roles"));
    return apiCall;
  }

  async function UpdateRole(data , id) {
    const apiCall = await PrivateApiCall.put(`/admin/roles/${id}`, data)
      .then((response) => response)
      .catch((error) => errorCatch(error, "Error deleting roles"));
    return apiCall;
  }


  export {
    GetRoles,
    DeleteRole,
    UpdateRole,
    CreateRole
  }